import hostedImageUrls from "../../utils/hosted_image";

// Data for the landing page mentors

const Data = [
  {
    mentor_image: hostedImageUrls.landing_mentor1,
    name: "Dipanjan De",
    role: "Full Stack Data Scientist",
    company: "Vince AS",
    experience: "8 years",
    Mentees: "200+",
    Rating: "4.5/5",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor2,
    name: "Anupama Mishra",
    role: "Product Design Lead",
    company: "PayPal",
    experience: "8 years",
    Mentees: "150+",
    Rating: "4/5",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor3,
    name: "Shivam Trivedi",
    role: "Assoc. Dir. of Product Management",
    company: "Lal10",
    experience: "8 years",
    Mentees: "130+",
    Rating: "4.3/5",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor6,
    name: "Aashish Gupta",
    role: "SDE II",
    company: "Uber",
    experience: "7+ year",
    Mentees: "100+",
    Rating: "4.6/5",
  },
  {
    mentor_image: hostedImageUrls.landing_mentor4,
    name: "Karanraj Mohanasundaram",
    role: "Application Developer",
    company: "Thoughtworks",
    experience: "2 years",
    Mentees: "80+",
    Rating: "4.2/5",
  },
];

export default Data;
