import { AiOutlineClose } from "react-icons/ai"


export const SelectProfileImagePopup = ({ ProfileImages,setProfileImageCon, handleSelectedImage, selectedImage, confirmImage}: any) =>{
    return (
      <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10' onClick={() => setProfileImageCon(false)}>
  
            <div className='bg-white p-5  rounded shadow-lg relative flex flex-col gap-[16px]' onClick={(e) => e?.stopPropagation()}>
              <h1 className='block text-center text-2xl font-semibold'>Select your avatar</h1>
              {/* <button className='absolute top-[50%] right-[1%]'><FaChevronRight className='text-[16px]' /></button> */}
              <button onClick={() => setProfileImageCon(false)} className='absolute top-[3%] right-[2%]'>
                <AiOutlineClose className='text-[16px]' />
              </button>
              <div className="lg:w-[600px] sm:w-[500px] w-full  rounded-xl flex flex-col gap-[16px] relative">
                <div className='grid md:grid-cols-5 sm:grid-cols-4 grid-cols-3 gap-[16px] w-full h-[350px] p-[8px] overflow-y-auto no-scrollbar'>
                  {
                    ProfileImages?.slice(1)?.map((image : any, index : number) => {
                      return (
                        <>
                          <div className={`mx-auto z-40 ${selectedImage?.key === image.key ? ' ' : ''}`} onClick={() => handleSelectedImage(image.key)} key={index}>
                            <img src={image?.path} alt="Mentor" className={`w-[80px] h-[80px] rounded-xl z-10 ${selectedImage?.key === image?.key ? 'opacity-70 border-2 border-txtPurple' : ''} duration-200 ease-in hover:scale-105`} />
                          </div>
                        </>
                      )
                    })
                  }
                </div>
                <div className='flex flex-row-reverse ' onClick={() => setProfileImageCon(false)}>
                  <button onClick={() => {
                    confirmImage()
                    setProfileImageCon(false)
                  }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row-reverse">
                    Select
                  </button>
                </div>
              </div>
              {/* Your popup content goes here */}
            </div>
          </div>
    )
  }