import MentorCard from "../../components/MentorCard/MentorCard";
import "../MentorCardsContainer/index.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import CardLoader from "../../components/CardLoader";
import hostedImageUrls from "../../utils/hosted_image";

interface MentorCardsContainerProps {
  browseMentorsCard?: boolean;
  limit?: string;
}

interface MentorData {
  mentor_id: string;
  name: string;
  tag: string;
  role: string;
  organization: string;
  profile_picture_url: string;
  experience: number;
  avaliableSession: string[];
  languages: string[];
}

export default function MentorCardsContainer({
  browseMentorsCard,
  limit,
}: MentorCardsContainerProps) {
  // fetch the mentor data from the backend and show the available mentor by mentor card component

  const [mentorData, setMentorData] = useState<MentorData[]>([]);
  const [hasDataLoaded, setHasDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          limit == null
            ? `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/list_mentors/`
            : `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/list_mentors/?limit=${limit}`, // changes
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
            },
          }
        );
        setMentorData(response?.data?.data);
        setHasDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (mentorData.length === 0 && hasDataLoaded == false) {
    return <CardLoader />;
  }

  return (
    <>
      {browseMentorsCard && (
        <div className="h-[416px]">
          <div className="mx-auto">
            <img
              className="browse-mentors-post"
              src={hostedImageUrls.browse_mentors_post}
              alt="mentors-post"
            />
            <span className="discover-mentor-text">
              Discover from over 10k mentors
            </span>
            <button className="browse-mentors-btn">Discover Mentors</button>
          </div>
        </div>
      )}
      {mentorData?.map((data, index) => (
        <MentorCard
          key={index}
          id={data?.mentor_id}
          name={data?.name}
          profilePic={data?.profile_picture_url}
          tag={data?.tag}
          language={""}
          designation={data?.role}
          organization={data?.organization}
          experience={data?.experience}
        />
      ))}
    </>
  );
}
