import Cookies from 'js-cookie';

interface CookieOptions {
    expires?: number;
    path?: string;
    sameSite?: 'None' | 'Lax' | 'Strict';
    secure?: boolean;
}

// Function to set a cookie
function setCookie(key: string, value: string, options: CookieOptions = { expires: 7, path: '/', sameSite: 'None', secure: true }) {
    Cookies.set(key, value, options);
}

// Function to get a cookie value by key
function getCookie(key: string): string | undefined {
    return Cookies.get(key);
}

// Function to delete a cookie by key
function deleteCookie(key: string) {
    Cookies.remove(key);
}

export {setCookie,getCookie,deleteCookie};