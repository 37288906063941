import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";

interface Props {     // Define the types of props that the component will receive
  push_front_to: string;
}

const ToggleHeader: React.FC<Props> = ({ push_front_to }) => {      // Define the component as a function component and pass the props to it
  const { userProfile } = useContext(AuthContext);    // Get the user profile from the AuthContext
  // navigation header for the mobile screen on the product page
  return (
    <>
      <div className="">
        <div
          className="flex flex-row py-[8px] pr-[8px]
                                items-center justify-between h-[80px]"
        >
            {/* logo of the organization and navigation to the landing page */}
            <Link to={'/mentee'} className="object-contain max-h-[80px] w-[180px]">
              <img src={hostedImageUrls.growbinar_logo_full} alt="Growbinar logo" />
            </Link>
          {/* profile image of the user and profile navigation */}
          <Link
            to={`/${push_front_to}`}
            className="flex flex-col items-center justify-center"
          >
            <div className="avatar w-[53px] hover:bg-hoverpurple hover-shadow-lg transition duration-300 p-1 rounded-md">
              <img
                className="cursor-pointer rounded-full overflow-hidden"
                src={(userProfile?.profile_image_url) ? userProfile?.profile_image_url : hostedImageUrls?.blankprofile}
                alt="profile"
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ToggleHeader;
