import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import ValidationError from '../../components/ValidationError';
import { Country, State } from 'country-state-city';
import { validateAdditionalDatas, validateAdditionDatasArray, validateCountryState, validateDOBFormat, validateFirstName, ValidatePhonenumber, validateProfilePicture } from '../../utils/stepperValidation/menteeValidation';
import Cookies from 'js-cookie';
import axios from 'axios';
import { interestedAreasOption, MenteeFormError, MenteeImage, languagesOptions, MenteeFormState } from '../../utils/MenteeStepperHelper';
import { AutoCompleteInput } from '../../components/AutoCompleteInput';
import { SelectProfileImagePopup } from '../../components/SelectProfileImagePopup';
import { Gender } from './data';
import Alert from '@mui/material/Alert';

interface editInterface {
  userId: string | undefined;
  setEditProfile: Function;
}

const EditProfile = (props: editInterface) => {
  const { userProfile } = useContext(AuthContext);
  const [profileImageCon, setProfileImageCon] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedImage, setSelectedImage] = useState<any>(MenteeImage[0]);
  const token = Cookies.get('GB_ACCESS_TOKEN')
  const [loading, setLoading] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState('' as any)

  const [area_of_expertiseInput] = useState<string>("")

  const [intrestedAreaOption] = useState<string[]>(interestedAreasOption);

  const [languagesOption] = useState<string[]>(languagesOptions);

  const [formState, setFormState] = useState<MenteeFormState>({
    first_name: "",
    last_name: "",
    country: "",
    is_email_verified: false,
    phone_number: "",
    languages: [],
    gender: "Male",
    date_of_birth: "",
    city: "",
    profile_picture_url: selectedImage?.url,
    areas_of_expertise: [],
    description: "",
    role: "",
    organization: "",
    currentStudy: "",
    is_experience: true,
  });

  const [formError, setFormError] = useState<MenteeFormError>({
    first_name: [],
    phone_number: [],
    date_of_birth: [],
    country: [],
    city: [],
    role: [],
    organization: [],
    areas_of_expertise: [],
    languages: [],
    currentStudy: [],
    description: [],
    profile_picture_url: [],
  });

  const [isError, setIsError] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredAreasOfExpertise, setFilteredAreasOfExpertise] = useState<string[]>(intrestedAreaOption);

  useEffect(() => {
    if (area_of_expertiseInput.length > 0) {
      setFilteredAreasOfExpertise(intrestedAreaOption?.filter((item) => item.toLowerCase().includes(area_of_expertiseInput?.toLowerCase())))
    }
    else {
      setFilteredAreasOfExpertise([])
    }
  }, [area_of_expertiseInput, intrestedAreaOption])

  const updateFormState = (key: keyof typeof formState, value: string) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        [key]: value,
      };
    });
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    key: keyof typeof formState,
    value: string,
    resetInputFiledAndRecommendation: Function,
  ) => {
    if (event.key === "Enter" && value !== "") {
      if (key as string === "area_of_interest") {
        key = "areas_of_expertise"
      }
      if ((key === "areas_of_expertise" || key === "languages") && formState[key].includes(value)) {
        event.currentTarget.value = "";
        resetInputFiledAndRecommendation();
        return;
      }
      const newInputValues = {
        ...formState,
        [key]: [...(formState[key] as string[]), value.trim()],
      };
      setFormState(newInputValues);
      event.currentTarget.value = "";
      resetInputFiledAndRecommendation();
    }
  };

  const handleSelectedImage = async (image: any) => {
    setSelectedImage(MenteeImage?.find((img) => img.key === image));
  }

  const confirmImage = () => {
    updateFormState('profile_picture_url', (MenteeImage?.find((img) => img?.key === selectedImage?.key) as { url: string })?.url);
  }

  const handleFormError = (key: keyof typeof formError, value: string[]) => {
    setFormError((prevStates) => {
      return {
        ...prevStates,
        [key]: value,
      };
    });
  };

  const handleCountryChange = async (event: any) => {

    const countryIsoCode = event?.target?.value;
    if (countryIsoCode === "") {
      updateFormState("country", "")
      updateFormState("city", "")
      setStates([])
      return;
    }

    setCountryCode(countryIsoCode)
    const country: any = Country?.getCountryByCode(countryIsoCode);
    updateFormState("country", country["name"])
  };

  useEffect(()=>{
    setCountryCode(Country.getAllCountries().find((country) => {
        return(country.name === formState?.country)
    })?.isoCode)
},[formState.country])

  useEffect(()=>{
    setStates(State.getStatesOfCountry(countryCode) as any);
  },[countryCode])


  const handleStateChange = (event: any) => {
    const stateIsoCode = event?.target?.value;
    if (stateIsoCode === "" || countryCode === "") {
      updateFormState('city', "")
      return
    }
    const state: any = State.getStateByCodeAndCountry(stateIsoCode, countryCode);
    updateFormState("city", state["name"])
  };

  const handleError = () => {
    const usernameValidation = validateFirstName(formState?.first_name);
    const phoneValidation = ValidatePhonenumber(formState?.phone_number);
    const dobValidation = validateDOBFormat(formState?.date_of_birth);
    const countryValidation = validateCountryState(
      formState?.country,
      "country"
    );
    const cityValidation = validateCountryState(formState?.city, "city");
    const profilePicValidation = validateProfilePicture(formState?.profile_picture_url, MenteeImage[0].url)
    const roleValidation = validateAdditionalDatas(formState?.role, "role");
    const organizationValidation = validateAdditionalDatas(
      formState?.organization,
      "organization"
    );
    const descriptionValidation = validateAdditionalDatas(
      formState?.description,
      "description"
    );
    const languagesValidation = validateAdditionDatasArray(
      formState?.languages,
      "languages"
    );
    const areas_of_expertiseValidation = validateAdditionDatasArray(
      formState?.areas_of_expertise,
      "areas_of_expertise"
    );

    const allValidation = [
      usernameValidation,
      phoneValidation,
      countryValidation,
      cityValidation,
      dobValidation,
      profilePicValidation,
      roleValidation,
      organizationValidation,
      descriptionValidation,
      languagesValidation,
      areas_of_expertiseValidation,
    ];

    let isFormValid = true;

    allValidation?.forEach((validation) => {
      if ((validation as { error: boolean }).error) {
        isFormValid = false;
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          (validation as { bucket: string[] }).bucket
        );
      } else {
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          []
        );
      }
    });

    return isFormValid;
  };

  const handlelImagePopup = () => {
    setProfileImageCon(!profileImageCon)
  }

  useEffect(() => {
    updateFormState('profile_picture_url', selectedImage?.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage])

  const handleOptionClick = (key: keyof typeof formState, value: string) => {
    if (key as string === "area_of_interest") {
      key = "areas_of_expertise"
    }
    if ((key === "areas_of_expertise" || key === "languages") && formState[key].includes(value)) {
      return;
    }
    setFormState((prevStates) => {
      return {
        ...prevStates,
        [key]: [...(formState[key] as string[]), value],
      };
    });
  }

  const handleMultiInputDelete = (
    key: keyof typeof formState,
    index: number
  ) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        [key]: [
          ...prevStates[key].slice(0, index),
          ...prevStates[key].slice(index + 1),
        ],
      };
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const idDatasValidated = handleError();
    if (idDatasValidated) {
      setIsError(false)
      const { areas_of_expertise: areas_of_interest, is_email_verified, ...rest } = formState
      setLoading(true)
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.patch(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/mentee_details/`,{
          ...rest,
          areas_of_interest,
        },{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        })
        if(response?.status === 200){
          window.location.reload()
        }
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
      }
    }
    else{
      setIsError(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Adds a smooth scrolling animation
      });
    }
    setLoading(false)
  };

  useEffect(() => {
    setSelectedImage(MenteeImage.find((img) => img.path === userProfile?.profile_image_url));
  }, [userProfile])


  useEffect(() => {
    if (userProfile) {
      // setSelectedImage(MenteeImage.find((img) => img.path === userProfile.profile_image_url));
      setFormState((prevStates) => {
        return ({
          ...prevStates,
          first_name: userProfile?.name.split(' ')[0],
          last_name: userProfile?.name.split(' ')[1],
          gender: userProfile?.gender,
          country: userProfile.country,
          city: userProfile.location,
          phone_number: userProfile.phone_number,
          areas_of_expertise: userProfile?.areas_of_interest,
          organization: userProfile?.organization,
          description: userProfile?.overview,
          role: userProfile?.role,
          profile_picture_url: userProfile?.profile_image_url,
          date_of_birth: userProfile.date_of_birth,
          languages: userProfile?.languages,
        })
      })
    }
  }, [userProfile])

  return (
    <>
      {profileImageCon && <SelectProfileImagePopup ProfileImages={MenteeImage} setProfileImageCon={setProfileImageCon} handleSelectedImage={handleSelectedImage} selectedImage={selectedImage} confirmImage={confirmImage} />}
      <div className={`p-[24px] ${profileImageCon ? '' : ''}`} id='#'>
        <div className='text-[#263238] text-[32px] font-bold p-2'>
          Edit your profile
        </div>
        <div className='text-[#464646] text-left text-[18px] px-2 leading-9 font-medium not-italic'>
          Update your details and preferences to make your profile more impactful
        </div>
        <div className='flex flex-col mt-4'>
          <div className={`flex flex-row gap-[12px] items-center`}>
            <button className={`w-[6.6rem] h-[6.6rem] rounded-full bg-[#A1A6AD] hover:bg-black overflow-hidden ${formError.profile_picture_url?.length > 0 ? 'border-2 border-red-500 shake' : ''}`} onClick={handlelImagePopup}>
              <img src={formState.profile_picture_url} alt='profile'></img>
            </button>
            <div className='flex flex-col gap-[8px]'>
              <p className="text-txtPurple font-semibold">Set a profile picture</p>
              <p className="text-txtGrey">select a file</p>
            </div>
          </div>
          <ValidationError errors={formError["profile_picture_url"]} />
        </div>
        <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
          {/* full name */}
          Your full name
        </div>
        <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row'>

          {/* first name */}
          <div>
            <input
              type="text"
              required value={formState.first_name}
              onChange={(e) => updateFormState('first_name', e.target.value)}
              placeholder="First name"
              className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.first_name?.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
            />
            <ValidationError errors={formError["first_name"]} />
          </div>

          {/* last name */}
          <input
            type="text"
            required value={formState?.last_name}
            onChange={(e) => updateFormState('last_name', e?.target?.value)}
            placeholder="Last name (optional)"
            className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple`}
          />

        </div>
        <div className='ml-0 md:ml-2 mt-4'>
          <textarea
            required value={formState?.description}
            onChange={(e) => updateFormState('description', e?.target?.value)}
            placeholder="Description"
            className={`border-[2px] min-h-[124px] border-[#c0c0c0] rounded-lg  w-full sm:w-[616px] h-[74px] text-left p-2 focus:outline-txtPurple ${formError?.description?.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
          />
          <ValidationError errors={formError["description"]} />
        </div>
        <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
          Personal details
        </div>
        <div className='flex gap-4 w-full sm:w-[306px]'>

          {/* gender  */}
          <select required id="gender" className={`outline-2 ml-0 sm:ml-2 w-[40%] border-[2px] border-[#c0c0c0] rounded-lg h-[54px] text-left p-2 focus:outline-txtPurple `} value={formState?.gender}
            onChange={(e) => {
              updateFormState('gender', e.target.value)
            }}>
            {
              Gender.map((item, index) => {
                return (
                  <option className='' value={item} key={index}>{item}</option>
                )
              })
            }
          </select>

          {/* date of birth */}
          <div className='md:w-[60%] w-full'>
            <input
              className={`border-[2px] border-[#c0c0c0] w-full rounded-lg h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.date_of_birth?.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`}
              type="date" id="dob" value={formState?.date_of_birth}
              placeholder="" required onChange={(e) => updateFormState('date_of_birth', e?.target?.value)}
            />
            <ValidationError errors={formError["date_of_birth"]} />
          </div>
        </div>
        {/* Phone number input */}
        <div className='flex px-0 md:px-2 flex-col mt-4'>
          <input
            type="text"
            value={formState?.phone_number}
            onChange={(e) => updateFormState('phone_number', e?.target?.value)}
            placeholder="phone number"
            className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.phone_number?.length > 0 ? '!border-2 border-red-500 shake' : ''}`}
          />
          <ValidationError errors={formError["phone_number"]} />
        </div>
        <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row mt-4'>
          <div>
            <select id="country" className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.country?.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`} required onChange={(e) => { handleCountryChange(e) }}>
              <option value={formState?.country} >{formState?.country}</option>
              {
                Country.getAllCountries().map((country, index) => {
                  return (
                    <option key={country?.isoCode} className='' value={country?.isoCode}>{country?.name}</option>
                  )
                })
              }
            </select>
            <ValidationError errors={formError["country"]} />
          </div>
          <div>
            <select id="state" className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.city.length > 0 ? ' outline outline-red-500 shake' : 'outline-none'}`} onChange={handleStateChange} required>
              <option value={formState?.city}>{formState?.city}</option>
              {
                (states as any[]).map((state, index) => {
                  return (
                    <option key={state?.isoCode} className='' value={state?.isoCode}>{state?.name}</option>
                  )
                })
              }
            </select>
            <ValidationError errors={formError["city"]} />
          </div>
        </div>
        <div className='text-[#464646] text-left text-[18px] p-2 leading-9 font-medium not-italic mt-4'>
          Professional details
        </div>
        <div className='flex px-0 md:px-2 gap-4 flex-col md:flex-row'>

          {/* role*/}
          <div>
            <input
              type="text"
              required value={formState?.role}
              onChange={(e) => updateFormState('role', e.target.value)}
              placeholder="your role"
              className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.role?.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
            />
            <ValidationError errors={formError["role"]} />
          </div>

          {/* organization */}
          <div>
            <input
              type="text"
              required value={formState.organization}
              onChange={(e) => updateFormState('organization', e?.target?.value)}
              placeholder="Course of Study"
              className={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.organization?.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
            />
            <ValidationError errors={formError["organization"]} />
          </div>
        </div>
        <div className='ml-0 md:ml-2 mt-4 md:max-w-[615px] flex flex-col gap-1'>
          <AutoCompleteInput
            id={"area_of_interest"}
            label=""
            formError={formError}
            options={intrestedAreaOption}
            formState={formState}
            handleKeyDown={handleKeyDown}
            handleOptionClick={handleOptionClick}
            handleMultiInputDelete={handleMultiInputDelete}
            placeholder="your interest"
            labelClassName="text-xl font-CircularStdMedium"
            inputClassName={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError?.areas_of_expertise?.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
            optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
          />
        </div>
        <div className='ml-0 md:ml-2 mt-4 md:max-w-[615px] flex flex-col gap-1'>
          <AutoCompleteInput
            id={"languages"} //formstate key
            label=""
            formError={formError}
            options={languagesOption}
            formState={formState}
            handleKeyDown={handleKeyDown}
            handleOptionClick={handleOptionClick}
            handleMultiInputDelete={handleMultiInputDelete}
            placeholder="Languages Known"
            labelClassName="text-xl font-CircularStdMedium"
            inputClassName={`border-[2px] border-[#c0c0c0] rounded-lg  w-full sm:w-[300px] h-[54px] text-left p-2 focus:outline-txtPurple ${formError.languages.length > 0 ? 'border-2 border-red-500 shake' : ''}`}
            optionsClassName={`flex flex-row items-center gap-[16px] bg-txtPurple text-white p-[12px] rounded-[32px] shadow-sm mr-[8px] whitespace-nowrap`}
          />
        </div>
        <div className='pl-0 md:px-2 mt-6 flex gap-4'>
          { !loading && <button onClick={() => { handleSubmit() }} className='px-4 py-2 bg-gbpurple text-white rounded-lg hover:opacity-75'>Submit</button>}
          { loading && <button onClick={() => { handleSubmit() }} className='px-4 py-2 bg-gbpurple text-white rounded-lg hover:opacity-75'><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                      <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                    </path>
                  </svg></button>}
          <button className='px-4 py-2 text-black border border-[#c0c0c0] rounded-lg hover:opacity-75' onClick={()=>{props.setEditProfile(false)}}>Close</button>
        </div>
        <div className={`${isError ? "block":"hidden"} mt-4`}>
          <Alert severity="error">
            Please fill all the required fields
          </Alert>
        </div>
      </div>
    </>
  )
}

export default EditProfile