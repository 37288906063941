import { Player } from '@lottiefiles/react-lottie-player'; 

const SingleCardLoader = () =>{   // Define the component as a function component and pass the props to itq
    // indicate the user to  wait till the data is loaded
    return(
        <>
            <div className="flex flex-row gap-[8px] flex-wrap md:justify-normal justify-center">
                <Player
                    src="https://lottie.host/18903955-6d5c-4b3a-8de9-1faed8f2a455/rHg1KLFDL1.json"
                    className="player"
                    loop
                    autoplay
                    style={{ width: '347px', height: '158px' }} 
                />
            </div>
        </>
    )
}

export default SingleCardLoader;