
import Data from "./data";
import "./index.css";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import hostedImageUrls from "../../utils/hosted_image";

export default function LandingPageMentors() {
  // LandingPageMentors component to display the mentors on the landing page
  const {authed} = useContext(AuthContext)  // Get the authed status from the AuthContext
  const navigate = useNavigate()

  function MentorsRemaining() {
    return (
      // remaining mentors section
      <div className="">

        {/* browse mentors section with shadow effect */}
        <div className="flex flex-col w-[385px] h-[588px] flex-shrink-0 rounded-xl  border border-[#D4D4D4] items-center justify-between ">
          <div className="mt-[40px]">
            <div className="">
              <img
                src={hostedImageUrls?.group_mentors}
                alt="group-mentors"
                className="w-[326px] h-[263px]"
              />
            </div>
            <div className=" w-[361px] h-[104px] font-CircularStdMedium whitespace-pre-line text-[48px] leading-[52px] text-center mt-[35px]">
              Browse over 100+ Mentors
            </div>
          </div>
          {/* {changeButton === "menteeLanding" ? ( */}
          <button 
            onClick={() => {
              if (authed?.authed === true) {
                navigate(`/${authed?.role}/home`)
              } else {
                navigate(`/signup`)
              }
              }}
             className="w-[321px] h-[69px] rounded-xl flex-shrink-0 bg-gbpurple mb-[20px] font-CircularStdMedium  text-[#fff] text-[24px] leading-10">
            Find my mentor
          </button>
          
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <div className="flex flex-row flex-wrap justify-center items-center gap-[24px] ">
        {Data.map((value, index) => (
          // mentor card component section
          <div className="flex flex-col gap-y-[16px]" key={index}>
            <div className="w-[345px] h-[487px] flex-shrink-0 rounded-xl border border-[#D4D4D4] space-y-[20px] ">
              <div className=" flex flex-col justify-center items-center m-2">
                <img
                  src={Data[index]?.mentor_image}
                  alt="mentor_image"
                  className="w-[361px] h-[340px] flex-shrink-0 rounded-md"
                  style={{ objectFit: 'cover', objectPosition: 'center 20%' }}
                />
              </div>
              <div className="flex flex-col items-start ml-[15px] space-y-[5px]">
                <p className="font-CircularStdBold text-[24px] leading-2">
                  {Data[index]?.name}
                </p>
                <p className="font-CircularStd text-[20px] text-[#959595] font-thin">
                  {Data[index]?.role}
                </p>
                <p className="font-CircularStd text-[18px] font-normal">
                  {Data[index]?.company}
                </p>
              </div>
            </div>
            <div className="w-[345px] h-[87px] pt-[17px] pr-[32px] pb-[16px] pl-[31px] items-center justify-center rounded-xl bg-[#F6F6F6] space-y-2">
              <div className="flex gap-[44px]">
                <div className="w-[85px] h-[54px]">
                  <p className="font-CircularStdMedium font-extralight text-[16px]">
                    Experience{" "}
                  </p>
                  <p className="font-CircularStdBold text-[15px]">
                    {Data[index]?.experience}
                  </p>
                </div>
                <div className="w-[85px] h-[54px] ">
                  <p className="font-CircularStdMedium font-extralight text-[16px]">
                    Mentees
                  </p>
                  <p className="font-CircularStdBold text-[15px]">
                    {Data[index]?.Mentees}
                  </p>
                </div>
                <div className="w-[85px] h-[54px]">
                  <p className="font-CircularStdMedium font-extralight text-[16px]">
                    Ratings
                  </p>
                  <p className="font-CircularStdBold  text-[15px]">
                    {Data[index]?.Rating}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <MentorsRemaining />
      </div>
  
    </div>
  );
}
