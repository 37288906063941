import spotify_logo from "../../assets/images/spotify_logo.svg";
import airbnb_logo from "../../assets/images/airbnb_logo.svg";
import linkedin_logo from "../../assets/images/linkedin_logo.svg";
import uber_logo from "../../assets/images/Uber_logo.svg";
import google_logo from "../../assets/images/google_logo.svg";
import amazon_logo from "../../assets/images/amazon_logo.svg";
import discord_logo from "../../assets/images/discord_logo.svg";
import figma_logo from "../../assets/images/figma_logo.svg";
import microsoft_logo from "../../assets/images/microsoft_logo.svg";
import notion_logo from "../../assets/images/notion_logo.svg";
import webflow_logo from "../../assets/images/webflow_logo.svg";
import netflix_logo from "../../assets/images/netflix_logo.svg";

const Companies = [
  {
    logo: notion_logo,
    logo1: spotify_logo,
  },
  {
    logo: spotify_logo,
    logo1: microsoft_logo,
  },
  {
    logo: airbnb_logo,
    logo1: webflow_logo,
  },
  {
    logo: linkedin_logo,
    logo1: airbnb_logo,
  },
  {
    logo: uber_logo,
    logo1: discord_logo,
  },
  {
    logo: amazon_logo,
    logo1: netflix_logo,
  },
  {
    logo: google_logo,
    logo1: spotify_logo,
  },
  {
    logo: figma_logo,
    logo1: uber_logo,
  },
  {
    logo: discord_logo,
    logo1: google_logo,
  },
];
export default Companies;
