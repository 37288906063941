import  { useState } from "react";
import SignupLoginHeroContent from "../../pages/SignUpLoginHero";

import SignUpContent from "../../pages/SignUp";
import LoginContent from "../../pages/Login";
import { useEffect } from "react";
import ResetPassword from "../../pages/ResetPassword";
import ForgotPassword from "../../pages/ForgotPassword";

interface SetStateProps {
  url:string;
}
// signup and login layout
const SignUpLoginLayout = ({url}:SetStateProps) => {
  const [state, setState] = useState(url);

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <div className="w-2/5 hidden lg:block bg-gbpurple">
          <SignupLoginHeroContent state={state} />
        </div>
        {/* login and signu component rendering */}
        <div className="flex-grow justify-between bg-white">
          {url === "login" ? (
            <LoginContent setSignup={setState} />
          ) : url === "signup" ? (
            <SignUpContent setLogin={setState} cntEmail="EmailSignup" />
          ): url==="resetpassword" ? <ResetPassword/> : <ForgotPassword setLogin={setState}/>}
        </div>
      </div>
    </div>
  );
}


export default SignUpLoginLayout;