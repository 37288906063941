import hostedImageUrls from "../../utils/hosted_image";

// data of the team members of growbinor

const cardData = [
  {
    name: "Gajendar Singh Rajput",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/gajendar.jpg",
    role: "Founder & CEO",
    content: "Leading with passion and purpose to drive Growbinar's success",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/gajendar-singh-rajput/"
  },
  {
    name: "Gowtham Sidharth",
    profileImage: "https://imgs.search.brave.com/mDdtZ12xiGTjupVmFywXaqmw7taeD-L12YCXsD02hPQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAxLzA3LzQzLzQ1/LzM2MF9GXzEwNzQz/NDUxMV9pYXJGMno4/OGM2RHM2QWxndHdv/dEhTQWt0V0NkWU9u/Ny5qcGc",
    role: " Chief Operating Officer",
    content:
      "Driving operational excellence at Growbinar with precision and strategy",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: ""
  },
  {
    name: "Hari",
    profileImage: "https://imgs.search.brave.com/mDdtZ12xiGTjupVmFywXaqmw7taeD-L12YCXsD02hPQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAxLzA3LzQzLzQ1/LzM2MF9GXzEwNzQz/NDUxMV9pYXJGMno4/OGM2RHM2QWxndHdv/dEhTQWt0V0NkWU9u/Ny5qcGc",
    role: "Chief Technology Officer",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    content: "Tech visionary shaping tomorrow's solutions",
    linkedInLink: ""
  },

  {
    profileImage: "https://imgs.search.brave.com/mDdtZ12xiGTjupVmFywXaqmw7taeD-L12YCXsD02hPQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAxLzA3LzQzLzQ1/LzM2MF9GXzEwNzQz/NDUxMV9pYXJGMno4/OGM2RHM2QWxndHdv/dEhTQWt0V0NkWU9u/Ny5qcGc",
    name: "Hemraj",
    role: "Chief Product Engineer",
    content: "Engineered for innovation, product perfection.",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: ""
  },

  {
    name: "Sandeep Singh",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/sandeep%20(1).png",
    role: "Brand Designer",
    content: "Forging timeless brands with creative mastery",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/sandeep-singh-6b076922b/"
  },
  {
    name: "Dipanjan De",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/Dipanjan%20De.jpeg",
    role: "Technical Director",
    content: "Engineering Innovation and Leading Technological Excellence",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/dipanjan131/"
  },
  {
    name: "Summia Parveen",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/summia.jpeg",
    role: "Mentor",
    content: "Cultivating Talent and Inspiring Excellence with Every Step",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/summiaparveen/"
  },
  {
    name: "Vivek Kumar",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/Vivek%20New%20-%20Compressed.jpg",
    role: "Director",
    content: "Steering the company towards strategic growth and success",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/vivekkumar3987/"
  },
  {
    name: "Dr. Sampath Kumar",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/Sampath%20Kumar.jpeg",
    role: "Mentor",
    content:
      "Inspiring Growth and Guiding Success through Expert Mentorship",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/sampath-kumar-s/"
  },
  {
    name: "Jutta Jerlich",
    profileImage: "https://gb-static-files.sirv.com/Frontend-Static/our-team/jutta.jpeg",
    role: "Mentor",
    content:
      "Empowering Minds, Shaping Future Leaders through Expert Guidance.",
    socialMedia: hostedImageUrls.our_teams_linkedin,
    linkedInLink: "https://www.linkedin.com/in/juttajerlich/"
  },
];

export default cardData;
