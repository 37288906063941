import { useNavigate } from "react-router-dom";


export default function MentorHero() {

  // mentor hero component
  
  const navigate = useNavigate()

  const handleBecomeMentor = () =>{
    navigate('/signup')
  }

  return (
    <div>
      <div className="flex items-center justify-center mt-[120px] ">
        <div className="ils-img-container">
          <div className="absolute inset-0 flex flex-col justify-center items-center">
            <div className="lg:w-[900px] md:w-[750px] md:h-[164px] text-center md:text-5xl text-3xl text-wrap">
              <p className="text-[#000] font-CircularStdBold md:leading-[82px] leading-[50px] tracking-1 whitespace-pre-line ">
                Lead, connect, and leave a legacy through mentorship
              </p>
            </div>
            <br />
            <div className="lg:w-[780px] md:w-[750px] text-center">
              <p className="text-[#000] lg:text-lg md:text-md font-light leading-8 tracking-2 whitespace-pre-line">
                Elevate your leadership journey, broaden connections, leave a
                lasting impact. With mentorship as your compass, success awaits.
              </p>
            </div>
            <div className="mt-[25px]">
              <button onClick={handleBecomeMentor} className="md:w-[285px] w-[250px] h-[78px]  gap-[10px] flex-shrink-0 rounded-[200px] bg-gbpurple shadow-newsletter text-lg font-CircularStdMedium font-semibold text-white text-center">
                Become a Mentor
              </button>
            </div>
            <div className="w-[296px] h-[46px] mt-[25px]">
              <p className="text-base font-light text-center">
                Explore more than 5,000 skills, topics, and companies to learn
                about.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
