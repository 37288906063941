import {  useContext, useEffect, useState } from "react";
import MenteeStepperProfile from "./menteeStepperProfile";
import MenteeStepperBio from "./menteeStepperBio";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./index.css";
import { useNavigate } from "react-router-dom";
import {
  validateCountryState,
  validateDOBFormat,
  validateFirstName,
  ValidatePhonenumber,
  validateAdditionalDatas,
  validateAdditionDatasArray,
  validateProfilePicture,
} from "../../utils/stepperValidation/menteeValidation";
import axios from "axios";
import Cookies from "js-cookie";
import toast , {Toaster} from "react-hot-toast";
import { Player } from "@lottiefiles/react-lottie-player";
import { AuthContext } from "../../contexts/AuthContext";
import { MenteeFormError, MenteeFormState, MenteeImage } from "../../utils/MenteeStepperHelper";
// consecutive  steps for the mentee stepper

const steps = [
  "Complete Your Profile Details",
  "Complete Your Education Details",
];


export default function MenteeStepper() {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const token = Cookies.get('GB_ACCESS_TOKEN')
  const [loading,setLoading] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<any>(MenteeImage[0]);
  const menteeTitle = [
    "Let's Make your Mentee profile",
    "Let's make your Education Details",
  ];

  const [formState, setFormState] = useState<MenteeFormState>({
    first_name: "",
    last_name: "",
    country: "",
    is_email_verified: false,
    phone_number: "",
    languages: [],
    gender: "Male",
    date_of_birth: "",
    city: "",
    profile_picture_url: selectedImage.url,
    areas_of_expertise: [],
    description: "",
    role: "",
    organization: "",
    currentStudy: "",
    is_experience: true,
  });

  const [formError, setFormError] = useState<MenteeFormError>({
    first_name: [],
    phone_number: [],
    date_of_birth: [],
    country: [],
    city: [],
    role: [],
    organization: [],
    areas_of_expertise: [],
    languages: [],
    currentStudy: [],
    description: [],
    profile_picture_url: [],
  });

  const {authed, setAuthed} = useContext(AuthContext);

  const handleStepper = (changeStep: number) => {
    if (step === 1 && changeStep === 1 && !handleProfileError()) {
      return;
    } else {
      setFormError((previousState) => {
        return {
          first_name: [],
          phone_number: [],
          date_of_birth: [],
          country: [],
          city: [],
          role: [],
          organization: [],
          areas_of_expertise: [],
          languages: [],
          currentStudy: [],
          description: [],
          profile_picture_url: [],
        };
      });
      setStep(step + changeStep);
    }
  };

  const handleProfileError = () => {
    const usernameValidation = validateFirstName(formState.first_name);
    const phoneValidation = ValidatePhonenumber(formState.phone_number);
    const dobValidation = validateDOBFormat(formState.date_of_birth);
    const countryValidation = validateCountryState(
      formState.country,
      "country"
    );
    const cityValidation = validateCountryState(formState.city, "city");
    const profilePicValidation = validateProfilePicture(formState.profile_picture_url, MenteeImage[0].url)

    const allValidation = [
      usernameValidation,
      phoneValidation,
      countryValidation,
      cityValidation,
      dobValidation,
      profilePicValidation
    ];

    let isFormValid = true;

    allValidation.forEach((validation) => {
      if ((validation as { error: boolean }).error) {
        isFormValid = false;
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          (validation as { bucket: string[] }).bucket
        );
      } else {
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          []
        );
      }
    });

    return isFormValid;
  };

  const handleBioError = () => {
    const roleValidation = validateAdditionalDatas(formState.role, "role");
    const organizationValidation = validateAdditionalDatas(
      formState.organization,
      "organization"
    );
    const currentStudyValidation = validateAdditionalDatas(
      formState.currentStudy,
      "currentStudy"
    );
    const descriptionValidation = validateAdditionalDatas(
      formState.description,
      "description"
    );
    const languagesValidation = validateAdditionDatasArray(
      formState.languages,
      "languages"
    );
    const areas_of_expertiseValidation = validateAdditionDatasArray(
      formState.areas_of_expertise,
      "areas_of_expertise"
    );
    const allValidation = [
      roleValidation,
      organizationValidation,
      currentStudyValidation,
      descriptionValidation,
      languagesValidation,
      areas_of_expertiseValidation,
    ];
    let isFormValid = true;
    allValidation.forEach((validation) => {
      if ((validation as { error: boolean }).error) {
        isFormValid = false;
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          (validation as { bucket: string[] }).bucket
        );
      } else {
        handleFormError(
          (validation as { key: keyof typeof formError }).key,
          []
        );
      }
    });

    return isFormValid;
  };


  const updateFormState = (key: keyof typeof formState, value: string) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        [key]: value,
      };
    });
  };

  const handleOptionClick = (key: keyof typeof formState, value: string) => {
    if(key as string === "area_of_interest"){
      key = "areas_of_expertise"
    }
    if((key === "areas_of_expertise" || key === "languages" ) && formState[key].includes(value)){
      return;
    }
    setFormState((prevStates) => {
      return {
        ...prevStates,
        [key]: [...(formState[key] as string[]),value],
      };
    });
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    key: keyof typeof formState,
    value: string,
    resetInputFiledAndRecommendation: Function,
  ) => {
    if (event.key === "Enter" && value !== "") {
      if(key as string === "area_of_interest"){
        key = "areas_of_expertise"
      }
      if((key === "areas_of_expertise" || key === "languages" ) && formState[key].includes(value)){
        event.currentTarget.value = "";
        resetInputFiledAndRecommendation();
        return;
      }
      const newInputValues = {
        ...formState,
        [key]: [...(formState[key] as string[]), value.trim()],
      };
      setFormState(newInputValues);
      event.currentTarget.value = "";
      resetInputFiledAndRecommendation();
    }
  };

  const handleMultiInputDelete = (
    key: keyof typeof formState,
    index: number
  ) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        [key]: [
          ...prevStates[key].slice(0, index),
          ...prevStates[key].slice(index + 1),
        ],
      };
    });
  };

  const handleFormError = (key: keyof typeof formError, value: string[]) => {
    setFormError((prevStates) => {
      return {
        ...prevStates,
        [key]: value,
      };
    });
  };

  useEffect(()=>{
    updateFormState('profile_picture_url', selectedImage.url);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedImage])

  const handleSelectedImage = async (image: any) => {
    if (image === selectedImage.key) {
      setSelectedImage(MenteeImage[0]);
      updateFormState("profile_picture_url", MenteeImage[0].url);
    } else {
      setSelectedImage(MenteeImage.find((img) => img.key === image));
      updateFormState(
        "profile_picture_url",
        (MenteeImage.find((img) => img.key === image) as { url: string })?.url
      );
    }
  }

  const handleSubmit = async () => {
    const isProfileValid = handleProfileError();
    const isBioValid = handleBioError();

    if (isProfileValid && isBioValid) {
      const {areas_of_expertise:areas_of_interest,is_email_verified,...rest} = formState
      setLoading(true)
      try{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/mentee_details/`,{
          ...rest,
          areas_of_interest,
        },{
          headers:{
            "Authorization":`Bearer ${token}`
          }
        })
        setLoading(false)
        setAuthed(()=>{
          return {
            ...authed,
            status:200
          }
        })
        toast.remove();
toast.success('Profile Created Successfully')
        setTimeout(()=>{
          navigate('/mentee/home')
        },2000)
      }catch(error : any){
        setLoading(false)
        toast.remove();
toast.error(error.response?.data?.message)
      } 
    }
  };

  return (
    <>
      <div className="pb-[12px] h-full ">
        <Toaster/>
        {
          loading && (
            <div className="h-[90vh] flex items-center justify-center">
              <Player
                src='https://lottie.host/41557a43-02dc-49a2-a398-a898f8e60be3/IshXgmtJEG.json'
                className="player"
                loop
                autoplay
              />
            </div>
          )
        }

        <div className={`${loading === false ? 'flex' : 'hidden'} w-[90%] mx-auto  flex-col gap-[16px]`}>
          <div>
            <h1 className="text-txtPurple font-bold md:text-[40px] text-[36px]">
              {menteeTitle[step - 1]}
            </h1>
          </div>
          <div className="w-full rounded-xl bg-white opacity-90  py-[20px] relative z-50">
            {step === 1 ? (
              <></>
            ) : (
              <button
                className=" flex items-center justify-center rounded-full absolute md:left-[2%] left-[.5%] top-[50%]"
                onClick={() => {
                  handleStepper(-1);
                }}
              >
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 3L3 20.5L22 38"
                    stroke="url(#paint0_linear_1_2605)"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2605"
                      x1="12.5"
                      y1="13"
                      x2="20.6948"
                      y2="38.4207"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            )}

            {step < 2 ? (
              <button
                className=" rounded-full absolute md:right-[2%] right-[1%] top-[50%]"
                onClick={() => {
                  handleStepper(1);
                }}
              >
                {" "}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 3L22 20.5L3 38"
                    stroke="url(#paint0_linear_1_2602)"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2602"
                      x1="12.5"
                      y1="13"
                      x2="4.30516"
                      y2="38.4207"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            ) : (
              <button
                className=" rounded-full absolute md:right-[2%] right-[1%] top-[50%]"
                onClick={handleSubmit}
              >
                {" "}
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 43 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 15.8526L15.0905 28L40 3"
                    stroke="url(#paint0_linear_1_2755)"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_2755"
                      x1="-5"
                      y1="13"
                      x2="40"
                      y2="16"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6C63FF" />
                      <stop offset="1" stop-color="#008CFF" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
            )}

            <div className="w-[80%] mx-auto flex flex-col gap-[20px] relative">
              <div>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={step - 1} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>

              <div className="min-h-[480px]">
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-[28px] gap-y-[16px]">
                  {step === 1 ? (
                    <MenteeStepperProfile
                      formState={formState}
                      updateFormState={updateFormState}
                      formError={formError}
                      handleSelectedImage={handleSelectedImage}
                      selectedImage={selectedImage}
                    />
                  ) : step === 2 ? (
                    <MenteeStepperBio
                      handleOptionClick = {handleOptionClick}
                      formState={formState}
                      updateFormState={updateFormState}
                      handleKeyDown={handleKeyDown}
                      handleMultiInputDelete={handleMultiInputDelete}
                      formError={formError}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
