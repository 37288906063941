import './ThreedotLoader.css'

const ThreedotLoader = () =>{
    return(
        <>
            
                <div className='flex flex-col'>
                    <div className="typing-indicator">
                        <div className="typing-circle"></div>
                        <div className="typing-circle"></div>
                        <div className="typing-circle"></div>
                        <div className="typing-shadow"></div>
                        <div className="typing-shadow"></div>
                        <div className="typing-shadow"></div>
                    </div>
                </div>

        </>
    )
}

export default ThreedotLoader;