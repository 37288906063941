import React from "react";
import { IoSearch } from "react-icons/io5";


const SearchBar: React.FC = () => {
  return (
    <>
    {/* search bar */}
    <div className='md:hidden items-center relative'>
              <label className='relative'>
                  <input type='search' className=' w-full bg-white p-[12px] border-0 rounded-lg outline-[1px] focus:outline-txtPurple'
              placeholder='Search mentors'
            />
            <IoSearch className="absolute top-[0.5%] right-[3%] text-[24px]"/>
          </label>
        </div>
    </>
  );
};

export default SearchBar;