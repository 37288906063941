import axios from "axios"
import Cookies from "js-cookie"


// Auth function to check if the user is authenticated or not

// return the different status based on the user authentication status

const Auth = async () => {

    try {
        const token = Cookies.get('GB_ACCESS_TOKEN')   // get the token from the cookies

        const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/common_endpoint/`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        
        return {status : 200 , authed:true ,verified: true, role:response?.data?.role}   // return the status and role of the user

    } catch (error: any) {
     
        if (error.response?.status === 403) {
            return { status: 403, authed: false ,verified:false, role:"" }   // return the status of the user if unauthorized
        }
        if (error.response?.status === 400 && error.response?.data?.message === "User details not completely entered.") {
            return { status: 400, authed: true ,verified:true, role:error.response?.data?.role }  // return the status and role of the user if the stepper is not filled
        }
        if (error.response?.status === 400 && error.response?.data?.message === "Verify your email") {
            return { status: 400, authed: true, verified: false, role: error.response?.data?.role }  // return the status and role of the user if the stepper is not filled
        }
    }
}

export default Auth;