import React from "react";
import { Link } from "react-router-dom";
interface CardProps {     // Define the types of props that the component will receive
  profileImage: string;
  name: string;
  role: string;
  content: string;
  media: string;
  linkedInLink: string | null;
}

const Card: React.FC<CardProps> = ({  // Define the component as a function component and pass the props to it
  profileImage,
  name,
  role,
  content,
  media,
  linkedInLink
}) => {
  // card component for the our team container where the details of the team members are displayed
  return (
    <div className="max-w-[310px] md:h-[500px] h-[550px] border-[2px] border-[#EAECF0] ">
      <div className="relative h-full">
        <div className="h-[300px] md:w-[306px] sm:w-full">
          <img src={profileImage} alt="profile" className="h-full w-full bg-no-repeat" style={{ objectFit: 'cover', objectPosition: 'center 20%' }}/>
        </div>
        <div className="sm:w-[278px] w-auto sm:h-[30px] p-3">
          <p className="text-lg leading-7 font-CircularStdBold">{name}</p>
        </div>
        <div className="sm:w-[278px] w-auto sm:h-[30px] p-3">
          <p className="text-base text-gbpurple font-CircularStdMedium font-normal">
            {role}
          </p>
        </div>
        <div className="sm:w-[98%] w-auto h-full px-3 py-1  ">
          <div className="text-[#5F6980] text-base font-CircularStdMedium font-normal whitespace-pre-line w-full">
            {content}
          </div>
        </div>
        {/* <div className="relative"> */}
          <div className="absolute bottom-6 left-3 sm:left-4 sm:bottom-4">
            <div className="flex justify-start">
              <div className="inline-flex justify-center items-center md:h-[40px] md:w-[40px] bg-slate-200 p-2 rounded-full">
                <Link to={linkedInLink as string} target="_blank"><img src={media} alt="linkedin"/></Link>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Card;
