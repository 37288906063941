import MentorUpcomingContainer from "../../containers/MentorUpcomingContainer";
import { useEffect, useState } from "react";
import "./index.css";
import "react-calendar/dist/Calendar.css";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Toaster } from "react-hot-toast";
import DateSessionPicker from "../../components/DatePicker";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import hostedImageUrls from "../../utils/hosted_image";
import Cookies from "js-cookie";
import axios from "axios";

// mentor dashboard product page


export default function MentorDashboard() {
  const today = new Date();
  const { userProfile } = useContext(AuthContext);    // get the user profile from the context
  const [value, setValue] = useState<Dayjs | null>(dayjs(today));

  useEffect(() => {
    getUserSessionCount()
  }, []);


  const [session_count, setSessionCount] = useState<any>({    // set the session count pending and upcoming sesions
    pendingCount: 0,
    upcommingCount: 0
  });

  const getUserSessionCount = async () => {   // function to get the user session count
    const uri = `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/session_count/`

    try {
      const response = await axios.get(uri, {
        headers: {
          "Authorization": `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`
        }
      })

      setSessionCount({
        pendingCount: response?.data?.pendingCount,
        upcommingCount: response?.data?.upcommingCount,
      })

    } catch (error: any) {
      console.log(error)
    }
  }

  return (
    <div className="md:bg-white bg-lightPurple">
      <Toaster />
      <div className="p-[16px] items-center justify-center gap-[10px] bg-[#DDDAFF] md:bg-transparent">
        <p className="text-3xl font-semibold text-black ">Welcome Back <span className="grad-text capitalize">{userProfile?.name} 🚀</span></p>
        {/* upcoming and pending request */}
        <p>
          You have
          <span className="text-gbpurple">
            {" "}
            {session_count?.upcommingCount} upcoming sessions and {session_count?.pendingCount} requests
          </span>
        </p>
      </div>
      <div className="p-[10px] flex flex-col gap-[16px]">
        <div className="">
          <p className="text-[#000] text-left text-[1.8rem] font-medium leading-[36px]">Upcoming sessions</p>
        </div>
        {/* //upcoming sessions cards */}
        <div className="items-center justify-center gap-[10px]">
          <MentorUpcomingContainer />
        </div>
        {/* horizontal divider */}
        <div className=" py-[20px]">
          <div className="border-2 border-slate-200 divide-y-2 w-full"></div>
        </div>

        {/*graph and container*/}
        <div className="flex md:gap-10 flex-col md:flex-row gap-[16px] flex-wrap lg:items-start sm:items-center relative sm:p-4">

          {/* mentor calendar section */}
          <div className="flex-shrink-0 ">
            <div className="h-auto flex flex-col gap-[20px] ">
              <p className=" text-[26px] font-CircularStdMedium">
                Add your available session
              </p>
              <div className="w-full flex flex-col gap-[16px] ">
                {/* <Calender className="react-calendar" /> */}
                <DateSessionPicker />
              </div>

            </div>
          </div>
          <div className="border border-[#c0c0c0] overflow-hidden rounded-md">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
            </LocalizationProvider>
          </div>
        </div>

        {/*Made with love from india text */}
        <div className="mt-20 flex items-center mb-[100px] md:mb-0 lg:w-[60%]">
          <p className="text-[#b9b5f9] text-4xl md:text-7xl font-CircularStdBold">Made with love for india.</p>
          <img className="w-[100px] h-[100px] sm:w-[200px] sm:h-[200px]" src={hostedImageUrls?.love_emoji} alt={"emoji"} />
        </div>
      </div>

    </div>
  );
}
