import Cookies from "js-cookie";
import "./index.css"
import { Link } from "react-router-dom";
import { convertToAMPM, getMonthDateFromDate } from "../../utils/HelperFunctions";

interface CardProps {
    sessionDetails:any;
}
export default function Cards({  sessionDetails }: CardProps) {
    /* Role must be fetched from cookies, designation & organization must come from backend response */
    const role = Cookies.get('role');
    const session_type = '1-1';
    return (
        <div className="">
            <div>
                <div className="flex flex-row items-center justify-between">
                    <img className="card-image" src={sessionDetails?.profile_pic} alt="profile " />
                    <div className="flex flex-col ml-3 mt-[9.5px]">
                        <p className="card-text">{sessionDetails?.Reasons}</p>
                        <p className="text-lg font-medium">{sessionDetails?.name}</p>
                        <p className="text-md font-light">{sessionDetails?.role} at <span className="!font-normal">{sessionDetails?.organisation}</span></p>
                    </div>
                    <span className="session-type-tag">{session_type}</span>
                </div>
            </div>
            <div className="flex flex-row flex-shrink-0 justify-between items-center">
                <p className="timing-text">{getMonthDateFromDate(sessionDetails?.date)} on {convertToAMPM(sessionDetails?.time)}</p>
                <Link target="_blank" to={sessionDetails?.meet_url || sessionDetails?.url} className="start-button hover:opacity-80 duration-100 ease-in">{role === 'mentor' ? 'Start' : 'Join'}</Link>
            </div>
        </div>
    );
}