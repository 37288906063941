import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

interface MenteeStepperAuthProps {
    children: React.ReactNode;
}




const MenteeStepperAuth: React.FC<MenteeStepperAuthProps> = ({ children }: { children: any }) => {

    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    // The isAuthenticated state variable represents whether the user is authenticated or not.
    // It is initially set to null and can be updated using the setIsAuthenticated function.
    const [isStepper, setIsStepper] = useState<boolean | null>(null);
    // The isStepper state variable represents whether the user filled the stepper or not.
    const [role, setRole] = useState<string | null>(null);
    // The role state variable represents the role of the authenticated user.
    // email verified or not
    const navigate = useNavigate();

    const [verify, setVerify] = useState<boolean | null>(null)

    const location = useLocation();
    const route_uri = location.pathname.split('/')[1].toLowerCase()

    const { authed } = useContext(AuthContext);   // get the authed object from the AuthContext

    useEffect(() => {

        if (authed?.status === 200) {   // check whether the user is authorized
            setIsAuthenticated(true)
            setIsStepper(true)
            setVerify(true)
            setRole(authed?.role)
            navigate(`/${authed?.role}/home`)
        }
        if (authed?.status === 400 && authed?.verified === true) {  // check whether the user filled the stepper
            setIsAuthenticated(true)
            setIsStepper(false)
            setVerify(true)
            setRole(authed?.role)
        }
        if (authed?.status === 400 && authed?.verified === false) {    // check whether the user filled the stepper
            setIsAuthenticated(true)
            setIsStepper(false)
            setVerify(false)
            setRole(authed?.role)
        }
        if (authed?.status === 403) {   // unauthorized user
            setIsAuthenticated(false)
            setIsStepper(false)
        }
    }, [authed?.role, authed?.status, authed?.verified, navigate])

  


    return (role === "mentor") ? <Navigate to="/unauthorized" /> :  // if the user is mentee, redirect to unauthorized page
        (isAuthenticated === false) ? <Navigate to="/login" /> :   // if the user is not authenticated, redirect to login page
            (isAuthenticated && !verify) ? <Navigate to="/signup/emailVerification" /> : // if the user is authenticated but not verified, redirect to email verification page
                (isAuthenticated === true && isStepper === true && verify && route_uri === "mentorstepper") ? <Navigate to="/mentor/home" /> :
                    (isAuthenticated && !isStepper && verify) ? <>{children}</> :    // if the user is authenticated and filled the stepper, render the children
                            <>
                                <Loader />
                            </>
}
export default MenteeStepperAuth;