export const Gender = ["Male", "Female", "Other", "Prefer not to say"]

export const interestedAreasOption = [
    "Web Development",
    "Mobile Development",
    "Game Development",
    "Software Design",
    "Data Science",
    "Machine Learning",
    "Artificial Intelligence",
    "Cybersecurity",
    "Cloud Computing",
    "DevOps",
    "Database Management",
    "Embedded Systems",
    "Internet of Things (IoT)",
    "Network Engineering",
    "Blockchain Technology",
    "Augmented Reality (AR)",
    "Virtual Reality (VR)",
    "Robotics",
    "UI/UX Design",
    "Project Management",
    "Quality Assurance (QA)",
    "Automation",
    "Big Data",
    "Business Intelligence",
    "Natural Language Processing (NLP)",
    "Computer Vision",
    "Quantum Computing"
]

export const languagesOptions = [
    "Hindi",
    "English",
    "Bengali",
    "Telugu",
    "Marathi",
    "Tamil",
    "Gujarati",
    "Kannada",
    "Odia",
    "Malayalam",
    "Punjabi",
    "Assamese",
    "Maithili",
    "Bhili/Bhilodi",
    "Santali"
];