import hostedImageUrls from "../../utils/hosted_image";

interface Props {
  state: string;
}

export default function SignupLoginHeroContent({ state }: Props) {
  // signup login page hero content
  return (
    <div>
      {/* left side container for the login and signup page based on the role */}
      {state === "login" ? (
        <div className="flex flex-col items-center justify-center mt-[315px]">
          <div className="w-[500px] h-[150px] ">
            <p className="signUpPage-text">Sign In</p>
            <p className="signUpPage-text">and grow your career</p>
          </div>
          <img
            src={hostedImageUrls.signin_character}
            alt="signinCharcter"
            className="w-[300px] h-[300px]"
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center mt-[315px]">
          <div className="w-[500px] h-[150px] ">
            <p className="signUpPage-text">Sign Up</p>
            <p className="signUpPage-text">and grow your career</p>
          </div>
          <img
            src={hostedImageUrls.signup_character}
            alt="signupCharcter"
            className="w-[300px] h-[300px]"
          />
        </div>
      )}
    </div>
  );
}
