import { Player } from '@lottiefiles/react-lottie-player';  // import the Player component from the lottiefiles library
import React from 'react';

interface CardLoaderProps {
    srcvideo?: string;
}


const CardLoader: React.FC<CardLoaderProps> = ({ srcvideo }) => {
    const defaultSrc = 'https://lottie.host/1d430282-1aa6-469f-8751-f958d519bf96/BiBgWXmOpG.json';  // Define the default source of the video

    return (
        <>
                    <Player
                        src={srcvideo || defaultSrc}
                        className="player "
                        loop
                        autoplay
                        style={{ maxWidth: '270px', maxHeight: '423.17px', margin: 'auto' }} // Add width and maxHeight here
                    />
           
              
                    <Player
                        src={srcvideo || defaultSrc}
                        className="player "
                        loop
                        autoplay
                        style={{ maxWidth: '270px', maxHeight: '423.17px', margin:"auto" }} // Add maxWidth and maxHeight here
                    />
             
            
                    <Player
                        src={srcvideo || defaultSrc}
                        className="player "
                        loop
                        autoplay
                        style={{ maxWidth: '270px', maxHeight: '423.17px',margin:"auto" }} // Add maxWidth and maxHeight here
                    />
     
                    <Player
                        src={srcvideo || defaultSrc}
                        className="player "
                        loop
                        autoplay
                        style={{ maxWidth: '270px', maxHeight: '423.17px', margin:"auto" }} // Add width and height here
                    />
        </>
    )
}

export default CardLoader;