import { Player } from '@lottiefiles/react-lottie-player';  // import the Player component from the lottiefiles library
import React from 'react';

interface LoaderProps {  // Define the types of props that the component will receive
    srcvideo?: string; 
}

// indicate the user to  wait till the data is loaded
const Loader : React.FC <LoaderProps> = ({srcvideo}) =>{ // Define the component as a function component and pass the props to it
    const defaultSrc = 'https://lottie.host/d5eafc44-43c8-4920-8fc3-163a7ed2c843/UhZfFe6slC.json'; // Define the default source of the video

    return(
        <> 
            <div className="h-screen flex items-center justify-center">
                <Player
                    src={srcvideo || defaultSrc}
                    className="player"
                    loop
                    autoplay
                />
            </div>
        </>
    )
}

export default Loader;