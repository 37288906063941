import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import { MdSupportAgent } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { VscSignOut } from "react-icons/vsc";
import { useState } from "react";
import axios from "axios"
import Cookies from "js-cookie";
import toast, {Toaster} from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
interface BackHeaderProps{  // Define the types of props that the component will receive
    push_back_to : string;
    curr_page:string;
}


const BackHeader : React.FC<BackHeaderProps> = ({ push_back_to , curr_page }) => {  // Define the component as a function component and pass the props to it
    const [options,setOptions] = useState<boolean>(false);
    const navigate = useNavigate();
  const { setAuthed } = useContext(AuthContext); 
    const deleteCookie = async () => {    // Function to delete the cookie and log out the user
        try {
            const response = await axios.get(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/logout/`, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`
                }
            });
            if (response?.status === 200) {
              toast.remove();
toast.success("Logged out successfully");
              Cookies.remove("GB_ACCESS_TOKEN");
              localStorage.removeItem("GB_USER_ID");
              localStorage.removeItem("userRole");
              Cookies.remove("role");
              setAuthed(400); // Set the auth status to false
              navigate("/"); // Redirect to the login page
            }
            else {
                toast.remove();
toast.error("Failed to logout");
            }
        }
        catch (error) {
            toast.remove();
toast.error("Failed to logout");
        }
    }

    const handleToggleOptions = () =>{    // Function to toggle the options
        setOptions(!options);
    }

    return(
        <>
            <Toaster/>
            <div className="h-[70px] p-[16px] flex flex-row gap-[16px] items-center justify-between">
                <div className="flex flex-row gap-[16px] items-center">
                    <Link to={ "/" + push_back_to}><IoMdArrowRoundBack className="text-[1.4rem]" /></Link>   {/* link to the previous page */}
                    <p className="font-CircularStdMedium text-2xl font-medium">{curr_page}</p>
                </div>
                <div className="relative">
                    <button onClick={handleToggleOptions}>  {/* button to toggle the options */}
                        <SlOptionsVertical className=" text-black z-10 text-[1.1rem]" />
                    </button>
                    {
                        options === true ? 
                            <div className="absolute right-[.7rem] top-[1.6rem] md:hidden flex flex-col p-[16px] gap-[16px] rounded-[16px] shadow-md bg-white">
                                <Link to={''}>
                                    <div className="flex flex-row gap-[8px] items-center">
                                        <MdSupportAgent />
                                        <span>Support</span>    
                                    </div>
                                </Link>
                                <Link to={''}>
                                    <div className="flex flex-row gap-[8px] items-center">
                                        <IoSettingsOutline />
                                        <span>Support</span>
                                    </div>
                                </Link>
                                <Link to={''}>
                                    <div className="flex flex-row gap-[8px] items-center" onClick={()=>deleteCookie()}>   {/* call the function to delete the cookie */}
                                        <VscSignOut />
                                        <span>LogOut</span>
                                    </div>
                                </Link>
                            </div>
                        :
                         <></> 
                    }
                    
                </div>
            </div>
          
        </>
    )
}

export default BackHeader;