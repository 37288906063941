import React, { useEffect, useState } from 'react'
import { MenteeFormError, MenteeFormState } from '../../utils/MenteeStepperHelper';
import { formStateType, MentorFormError } from '../../utils/MentorUtils';
import ValidationError from '../ValidationError';
import { MdClose } from 'react-icons/md';

interface AutoCompleteInputProps {
    id: any;
    label: string;
    formError: MenteeFormError | MentorFormError;
    options: string[];
    handleOptionClick: Function;
    formState: MenteeFormState | formStateType;
    handleKeyDown: Function;
    handleMultiInputDelete: Function;
    placeholder? : string;
    labelClassName : string;
    inputClassName : string;
    optionsClassName : string;
}


export const AutoCompleteInput = ({
    id,
    label,
    formError,
    options,
    formState,
    handleKeyDown,
    handleOptionClick,
    handleMultiInputDelete,
    placeholder,
    labelClassName,
    inputClassName,
    optionsClassName
  }: AutoCompleteInputProps) => {
    const [userInputValue, setUserInputValue] = useState<string>("");
    const [filteredData, setFilteredData] = useState<string[]>([]);
    useEffect(()=>{
        if(userInputValue?.length > 0){
            setFilteredData(options?.filter((item) => item?.toLowerCase().includes(userInputValue?.toLowerCase())))
        }
        else{
            setFilteredData([])
        }
    },[userInputValue, options])
    return (
      <>
          <label htmlFor="area_of_expertise" className={labelClassName}>{label}</label>
         <input
            id="areas_of_expertise"
            className={inputClassName}
            placeholder={placeholder}
            value={userInputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInputValue(e?.target?.value)}
            onKeyDown={(event) => handleKeyDown(event, id, (filteredData?.length)?
                filteredData[0]: "others", ()=>{
                    setUserInputValue('')
                    setFilteredData([])
                })}
        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFormState('areas_of_expertise', e.target.value)}
         />
        <div className='md:w-[86%] w-full'>
            <ValidationError errors={formError[(id === "area_of_interest")? "areas_of_expertise":id as keyof (MenteeFormError | MentorFormError)]} />
        </div>
        <div className="w-full relative">
                <div className="absolute md:w-[90%] max-h-[150px] overflow-y-auto shadow-md  overflow-hidden">
                {(filteredData?.length > 0)?
                    filteredData?.map((item, index) => {
                        return (
                            <button key={index} className={`w-full flex justify-start p-2 border-b border-gray-200 hover:bg-gray-200 hover:shadow-md cursor-pointer ${(index === 0)? 'bg-blue-100':"bg-white"}`}
                                onClick={(e) => {
                                    handleOptionClick(id, item);
                                    setFilteredData([])
                                    setUserInputValue('')
                                }}
                            >
                                <span>{item}</span>
                            </button>
                        )
                    }): (userInputValue?.length)? <>
                        <button className="w-full flex justify-start p-2 border-b border-gray-200 hover:bg-gray-200 hover:shadow-md cursor-pointer bg-white"
                        onClick={(e) => {
                            handleOptionClick(id, "others");
                            setFilteredData([])
                            setUserInputValue('')
                        }} 
                        >
                            <span>Others</span>
                        </button>
                    </> : <></>
                }
            </div>
        </div>
        <div className="flex flex-row gap-[8px] overflow-x-auto pb-2 scroll-width">
            {
                ( id === "area_of_interest")?
                    formState["areas_of_expertise"]?.map((item, index) => {
                        return (
                            <div key={index} className={optionsClassName}>
                                <span>{item}</span>
                                <button className="text-white" onClick={(e) => handleMultiInputDelete('areas_of_expertise', index)}>
                                    <MdClose/>
                                </button>
                            </div>
                        )
                    }):(
                        <>
                            {formState["languages"]?.map((item, index) => {
                                return (
                                    <div key={index} className={optionsClassName}>
                                        <span>{item}</span>
                                        <button className="text-white" onClick={(e) => handleMultiInputDelete('languages', index)}>
                                            <MdClose/>
                                        </button>
                                    </div>
                                )
                            })}
                        </>
                    )
            }
        </div>
        
      </>
    );
  };
