import "./index.css";
import buildingIcon from "../../assets/images/building.svg";

interface MentorExperienceCardProps {     // Define the types of props that the component will receive
  designation?: string;
  organization?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  experienceType?: string;
}


// mentor experience card provides the details of the mentor like designation, organization, work period and description

export default function MentorExperienceCard(props: MentorExperienceCardProps) {

  //get month and year form this 2022-01-01T00:00:00Z
  const getMonthYear = (date: string | undefined) => {
    if(date === "null"){
      return "working"
    }
    if(date){
      const dateObj = new Date(date);
      return dateObj?.toLocaleString("default", { month: "short" }) + " " + dateObj?.getFullYear();
    }
    return "working"
  };

  return (
    <div className=" border-b mb-2 border-[#c0c0c0] MentorExperienceCard">
      <div className="w-full flex justify-between flex-col lg:flex-row">
        <div className="flex flex-col justify-between gap-4 sm:flex-row">
          <div className="flex flex-col items-start sm:items-center sm:flex-row org-and-role">
            <div className="flex gap-4">
              <img
                src={buildingIcon}
                alt="buildingIcon"
                className="building-icon"
              />
              {/* designation of the mentor */}
              <p className="max-w-[200px] truncate designation">{props?.designation}</p>
            </div>
            {/* organization of the mentor */}
            <p className="min-w-[200px] truncate organization">{props?.organization}</p>
          </div>
        </div>
        <div className="flex items-center justify-center min-w-[150px] max-w-[150px] my-2 min-h-[45px] work-period">
          {/* in which year stated and ended  */}
          {getMonthYear(props?.startDate) + " - " + getMonthYear(props?.endDate)}
        </div>
      </div>
      <div className=" whitespace-normal experience-description">{props?.description}</div>
    </div>
  );
}
