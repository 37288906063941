import React from "react";
import CardContainer from "../../containers/ourTeamContainer/CardContainer";
import cardData from "./OurTeam";


// OurTeam page mentions the team behind growbinar

const ourTeam: React.FC = () => {
  return (
    <div className="mt-[120px]">
      <div className="flex flex-col flex-wrap items-center justify-center">
        <div></div>
        <p className="font-CircularStdBold md:p-0 p-[16px] md:text-[54px] text-[32px] text-black md:leading-[62px] leading-[32px] font-bold mt-2">
          Meet the{" "}
          <span className="font-CircularStdBold md:text-[54px] text-[32px] text-gbpurple leading-[62px] font-bold">
            Growbinar
          </span>{" "}
          Team
        </p>
        <p className="text-[#5F6980] md:text-md text-lg leading-9 font-CircularStdMedium mt-2 p-[16px]">
          Meet the visionary minds behind Growbinar and our dedicated team
          members
        </p>
      </div>
      <div className="flex items-center justify-center mt-8">
        {/* our Team container  */}
        <CardContainer cards={cardData} />
      </div>
    </div>
  );
};

export default ourTeam;
