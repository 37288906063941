
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicMentee from "./PublicMentee";
import PrivateMentee from "./PrivateMentee";

export default function MenteeProfile() {

  const current_userId = localStorage.getItem('GB_USER_ID')   // id of the current logged in user
  // const [menteeProfileDetails, setMenteeProfileDetails] = useState<any | null>(null)
  const {userId} = useParams()   // id of the searched user
  const [role, setRole] = useState<string>('')   // role of the current logged in user
  
  useEffect(()=>{
    setRole(localStorage.getItem('userRole') as string);
  },[])
  return (
    <div>
      {/* if current id and searched id are same it means private profile */}
      {
        current_userId === userId && role === "mentee" ? 
            <PrivateMentee userId={current_userId} /> 
              : <PublicMentee current_user={current_userId} view_user = {userId} />  // else public profile
      }
    </div>
  )
}
