import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import hostedImageUrls from "../../utils/hosted_image";

export interface ContactusInterface {
  name: string;
  email: string;
  phoneNumber: string;
  query: string;
}
export default function Contactus() {
  const [hasErrors, setHasErrors] = useState({
    name: false,
    email: false,
    query: false,
    phoneNumber: false,
  });

  const [formState, setFormState] = useState<ContactusInterface>({
    name: "",
    email: "",
    phoneNumber: "",
    query: "",
  });
  const updateFormState = (key: keyof typeof formState, value: string) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  //   validation for name, phonenumnber, email and message part
  const validateName = (name: string) => {
    const regex = /^[a-zA-Z ]+$/;
    const t = name.trim();
    return regex.test(t) && t.length > 0;
  };
  const validatePhonenumber = (phone: string) => {
    const regex = /^(\+?91)?[\s-]?[0-9]{5}[\s-]?[0-9]{5}$/;
    return regex.test(phone) && phone.trim().length > 0;
  };
  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email) && email.trim().length > 0;
  };
  const validateQuery = (query: string) => {
    const val = query.trim();
    if (val.length === 0) {
      return false;
    }
    return true;
  };
  //handling submission case on checking all the req fields
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let isValidName = validateName(formState.name);
    let isValidEmail = validateEmail(formState.email);
    let isValidPhoneNumber = validatePhonenumber(formState.phoneNumber);
    let isValidQuery = validateQuery(formState.query);

    const isValid = isValidEmail && isValidName && isValidPhoneNumber && isValidQuery;

    setHasErrors({
      name: !isValidName,
      email: !isValidEmail,
      phoneNumber: !isValidPhoneNumber,
      query: !isValidQuery,
    });

    if (isValid) {
      try{
        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/query/`, {
          name: formState?.name,
          email: formState?.email,
          phone_number: formState?.phoneNumber,
          query: formState?.query,
        }, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
          },
        })
        if(response?.status === 200){
          toast.remove();
toast.success("Submitted successfully")
          setFormState({
            name: "",
            email: "",
            phoneNumber: "",
            query: "",
          });
        }
        else{
          toast.remove();
toast.error("Something went wrong, try again later !")
        }
      }
      catch(error){
        console.log(error)
      }
      
    }else{
      toast.remove();
toast.error("Check Input Format !", {position:"top-right"})
    }
  };
  return (
    <div>
      <Toaster/>
      <div className="flex items-center justify-center mt-[100px] h-[80vh]">
        {/* contact us hero page  */}

        <div className="hidden lg:flex flex-col w-[500px] bg-gbpurple p-4 rounded-lg lg:rounded-r-none h-full">
          <div className="text-lg font-CircularStdMedium text-white items-center text-center">
            Please feel free to talk to us if you have any queries
          </div>
          <div className="text-lg font-CircularStdMedium text-white text-center">
            We endeavour to answer ASAP!
          </div>
          <img src={hostedImageUrls?.contactus} alt="contactus" />
        </div>

        {/* contact us form  */}
        <form onSubmit={handleSubmit} className="h-full">
          <div className="flex flex-col h-full items-center justify-center sm:w-[500px] w-[350px] p-4 bg-white space-y-5 border border-gbpurple lg:rounded-r-lg lg:rounded-l-none rounded-lg">
            <div className="font-CircularStdBold text-2xl text-gbpurple text-center">
              Contact Us
            </div>

            {/* input -> name */}
            <div className="flex flex-col gap-y-[8px] w-full">
              <label htmlFor="" className="text-lg font-CircularStdMedium">
                Name
              </label>
              <input
                className={`bg-white !opacity-100  outline-2 outline-gray-100 focus:outline-txtPurple duration-200 ease-in 
              rounded-[32px] shadow-md p-[12px] 
              ${!hasErrors.name ? "" : "border-2 border-red-500"}`}
                onChange={(e) => updateFormState("name", e?.target?.value)}
                placeholder="Enter your name"
                value={formState?.name}
              />
              {/* {hasErrors.name ? (
                <div>
                  <p className="text-sm font-CircularStdMedium text-red-500">
                    {" "}
                    Please enter a valid name
                  </p>
                </div>
              ) : (
                ""
              )} */}
            </div>
            {/* input -> email */}
            <div className="flex flex-col gap-y-[8px] w-full">
              <label htmlFor="" className="text-lg font-CircularStdMedium">
                Email
              </label>
              <input
                className={`bg-white !opacity-100 outline-2 outline-gray-100 focus:outline-txtPurple duration-200 ease-in 
              rounded-[32px] shadow-md p-[12px] 
              ${!hasErrors.email ? "" : "border-2 border-red-500"}`}
                onChange={(e) => updateFormState("email", e.target.value)}
                placeholder="Enter your email"
                value={formState?.email}
              />
              {/* {hasErrors.email ? (
                <div>
                  <p className="text-sm font-CircularStdMedium text-red-500">
                    {" "}
                    Please enter a valid email
                  </p>
                </div>
              ) : (
                ""
              )} */}
            </div>
            {/* input -> phonenumber */}

            <div className="flex flex-col gap-y-[8px] w-full">
              <label htmlFor="" className="text-lg font-CircularStdMedium">
                Phone Number
              </label>
              <input
                className={`bg-white !opacity-100  outline-2 outline-gray-100 focus:outline-txtPurple duration-200 ease-in 
              rounded-[32px] shadow-md p-[12px] 
              ${!hasErrors.phoneNumber ? "" : "border-2 border-red-500"}`}
                onChange={(e) => updateFormState("phoneNumber", e.target.value)}
                placeholder="Enter your name"
                value={formState?.phoneNumber}
              />
              {/* {hasErrors.phoneNumber ? (
                <div>
                  <p className="text-sm font-CircularStdMedium text-red-500">
                    {" "}
                    Please enter a valid PhoneNumber
                  </p>
                </div>
              ) : (
                ""
              )} */}
            </div>
            {/* input -> query */}

            <div className="flex flex-col gap-y-[8px] w-full">
              <label htmlFor="" className="text-lg font-CircularStdMedium">
                Query
              </label>
              <input
                className={`bg-white !opacity-100 outline-2 outline-gray-100 focus:outline-txtPurple duration-200 ease-in 
              rounded-[32px] shadow-md p-[12px] 
              ${!hasErrors.query ? "" : "border-2 border-red-500"}`}
                onChange={(e) => updateFormState("query", e.target.value)}
                placeholder="Enter your query..."
                value={formState?.query}
              />
            </div>
            <button
              type="submit"
              className="h-[50px] w-[100px] bg-gbpurple p-2 text-[#fff] font-CircularStdMedium text-base rounded-lg"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
