import { Navigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

interface MenteeAuthProps {
    children: React.ReactNode;
}

/**
 * MenteeAuth component is responsible for handling authentication and authorization logic for mentees.
 * It renders different components based on the authentication status and role of the user.
 */
const MenteeAuth: React.FC<MenteeAuthProps> = ({ children }: { children: any }) => {
    /**
    * Indicates whether the user is authenticated or not.
    * - `true` if the user is authenticated.
    * - `false` if the user is not authenticated.
    * - `null` if the authentication status is not determined yet.
    */
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    /**
      * Indicates whether the user is in the stepper mode or not.
      * - `true` if the user is in the stepper mode.
      * - `false` if the user is not in the stepper mode.
      * - `null` if the stepper mode is not determined yet.
      */
    const [isStepper, setIsStepper] = useState<boolean | null>(null)
    /**
     * Represents the role of the authenticated user.
     * - `null` if the role is not determined yet.
     * - Possible values: "mentor", "mentee", etc.
     */
    const [role, setRole] = useState<string | null>(null)

    // email verified or not

    const [verify, setVerify] = useState<boolean | null>(null)

    const { authed } = useContext(AuthContext);

    const location = useLocation();   // uselocation hook

    useEffect(() => {
        if (authed?.status === 200) {  // check whether the user is authorized
            setIsAuthenticated(true)
            setIsStepper(true)
            setVerify(true)
            setRole(authed?.role)
        }
        if (authed?.status === 400 && authed?.verified === true) {    // check whether the user filled the stepper
            setIsAuthenticated(true)
            setIsStepper(false)
            setVerify(true)
            setRole(authed?.role)
        }
        if (authed?.status === 400 && authed?.verified === false) {    // check whether the user filled the stepper
            setIsAuthenticated(true)
            setIsStepper(false)
            setVerify(false)
            setRole(authed?.role)
        }
        if (authed?.status === 403) {    // unauthorized user
            setIsAuthenticated(false)
            setIsStepper(false)

        }
    }, [authed?.role, authed?.status, authed?.verified])


    return (role === "mentor") ? <Navigate to="/unauthorized" /> :      // if user is mentor he is not allowed to mentee dashboard
        (isAuthenticated && !verify) ? <Navigate to="/signup/emailVerification" /> :  // if user is authenticated but not verified
            (isAuthenticated === true && isStepper === true && verify && location.pathname.toLowerCase() === "/menteestepper") ? <Navigate to="/mentee/home" /> :
                (isAuthenticated && isStepper && verify) ? <>{children}</> :         // if user is authenticated and filled the stepper
                    (isAuthenticated === true && isStepper === false && verify) ? <Navigate to="/menteestepper" /> :  // if user is authenticated but not filled the stepper
                        (isAuthenticated === false) ? <Navigate to="/" /> : // if user is not authenticated
                            <>
                                <Loader />
                            </>
}

export default MenteeAuth;