import React from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { FaRegClock } from "react-icons/fa6";
import  { Toaster } from "react-hot-toast";
import { getDayFromDate, getMonthDateFromDate, convertToAMPM } from "../../utils/HelperFunctions";


interface JoinBookingProps {   // Define the types of props that the component will receive
    setPopUp: Function;
    sessionDetails:any;
    setStartedSessionId: Function;
    popupMode: string;
}

const JoinBooking: React.FC<JoinBookingProps> = ({ setPopUp,  sessionDetails, setStartedSessionId, popupMode }) => {
    // Define the component as a function component and pass the props to it

    const username = sessionDetails?.mentee ? sessionDetails?.mentee : sessionDetails?.name;  // Define the username based on the session details

    return (
        <>
            <Toaster />
            <div className="fixed inset-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-20" onClick={() => setPopUp(false)}>
                <div className="!bg-white rounded-xl p-[24px] text-black relative flex flex-col gap-[16px] max-w-[450px] !leading-none" onClick={(e) => e?.stopPropagation()}>
                    <button className="absolute top-[16px] right-[16px]" onClick={() => setPopUp(false)}>
                        <IoCloseCircleOutline className="text-[24px] hover:text-red-600" />
                    </button>
                    <div className="flex flex-col gap-[8px] mt-[16px] items-center">
                        {/* confirm the session or join the session based on the session status */}
                        <h1 className="text-xl font-medium">
                            {
                                popupMode === "confirm" ? "Confirm your session" : "Join the session"
                            }
                        </h1>
                        <div className="flex flex-row gap-[8px] items-center">
                            <p className="text-gray-600 text-lg font-medium">
                                {
                                    popupMode === "confirm" ? `You are about to book a session with ${username}` : `You are about to join a session with ${username}`
                                }
                            </p>
                            <img className="w-[30px] h-[30px] rounded-full" src={sessionDetails?.profile_pic} alt="profile"></img>
                        </div>

                        
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-[#3F3D4D] font-medium">Selected time slot</h4>
                        <div className="flex flex-row gap-[16px]">
                            <div className="p-[12px] rounded-[8px] bg-[#F5F5F5] flex flex-row gap-[8px] items-center">
                                <SlCalender className="text-[16px] font-medium" />
                                {/* session data and timing and the slot allocted */}
                                <p className="font-medium">{getMonthDateFromDate(sessionDetails?.date)}, {getDayFromDate(sessionDetails?.date)}</p>
                            </div>
                            <div className="p-[16px] rounded-[8px] bg-[#F5F5F5] flex flex-row gap-[8px] items-center">
                                <FaRegClock className="text-[16px] font-medium" />
                                {/* Timing of the session */}
                                <p className="font-medium">{convertToAMPM(sessionDetails?.time)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-[#3F3D4D] font-medium">
                            {/* status based query */}
                            {
                                popupMode === "confirm" ? "Tell mentor what do you want to talk about" : "Reason for the session"
                            }
                        </h4>
                        <div className="flex flex-row flex-wrap gap-[16px]">

                                        <button  className={` p-[12px] whitespace-nowrap border bg-[#FBFBFE]  rounded-[8px] `} >
                                           {sessionDetails?.reason || sessionDetails?.Reasons}
                                        </button>

                        </div>
                    </div>
                    {/* meet link for session */}
                    {
                        
                            <div>
                                <button className="w-full p-[16px] bg-txtPurple text-white  font-medium rounded-xl mt-[12px]" onClick={()=>{
                                        setStartedSessionId(sessionDetails?.session_id)
                                        setPopUp(false);
                                        window.open(sessionDetails?.meet_url || sessionDetails?.url)
                                    }}>Join the Session</button>
                            </div>
                        
                    }
                </div>
            </div>
        </>
    )
}

export default JoinBooking;