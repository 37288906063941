import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <div className="h-screen max-w-screen overflow-x-hidden overflow-y-auto ">
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </div>
    </React.StrictMode>
);
