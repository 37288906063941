import { Link } from 'react-router-dom';
import hostedImageUrls from '../../utils/hosted_image';

const UnAuth = () =>{
    // 403 UnAuthorized page
    return(
        <>  
            <div className='h-screen flex items-center justify-center flex-col gap-[16px]'>
                <img src={hostedImageUrls.unauth} className='' alt='img'></img>
                <h1 className='text-3xl font-semibold'><span className='text-txtPurple'>403</span> UnAuthorized</h1>
                <div className='flex flex-row gap-[16px]'>
                    <Link to='/login' className='text-blue-500 hover:underline '>Login</Link>
                    <Link to='/mentee/home' className='text-blue-500 hover:underline '>Mentee</Link>
                    <Link to='/mentor/home' className='text-blue-500 hover:underline'>Mentor</Link>
                </div>

            </div>
        </>
    )
}

export default UnAuth;