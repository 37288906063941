import hostedImageUrls from "../hosted_image";

export interface MenteeFormState {
    first_name: string;
    last_name: string;
    country: string;
    is_email_verified: boolean;
    phone_number: string;
    languages: string[];
    gender: string;
    date_of_birth: string;
    city: string;
    profile_picture_url: string;
    areas_of_expertise: string[];
    description: string;
    role: string;
    currentStudy: string;
    organization: string;
    is_experience: boolean;
  }

  export interface MenteeFormError {
    first_name: string[];
    phone_number: string[];
    date_of_birth: string[];
    country: string[];
    city: string[];
    role: string[];
    organization: string[];
    areas_of_expertise: [];
    languages: string[];
    currentStudy: string[];
    description: string[];
    profile_picture_url: []
  }

export const MenteeImage = [
    {
      path: hostedImageUrls.plus_icon,
      url: hostedImageUrls.plus_icon,
      key: 0,
    },
    {
      path: hostedImageUrls.Mentee_M_1,
      url: hostedImageUrls.Mentee_M_1,
      key: 1,
    },
    {
  
      path: hostedImageUrls.Mentee_M_2,
      url: hostedImageUrls.Mentee_M_2,
      key: 2,
    },
    {
      path: hostedImageUrls.Mentee_M_3,
      url: hostedImageUrls.Mentee_M_3,
      key: 3,
    },
    {
      path: hostedImageUrls.Mentee_G_1,
      url: hostedImageUrls.Mentee_G_1,
      key: 4,
    },
    {
  
      path: hostedImageUrls.Mentee_G_2,
      url: hostedImageUrls.Mentee_G_2,
      key: 5,
    },
    {
  
      path: hostedImageUrls.Mentee_G_3,
      url: hostedImageUrls.Mentee_G_3,
      key: 6,
    },
    {
      path: hostedImageUrls.Mentee_M_4,
      url: hostedImageUrls.Mentee_M_4,
      key: 7,
    },
    {
      path: hostedImageUrls.Mentee_M_5,
      url: hostedImageUrls.Mentee_M_5,
      key: 8,
    },
    {
      path: hostedImageUrls.Mentee_M_6,
      url: hostedImageUrls.Mentee_M_6,
      key: 9,
    },
  
    {
      path: hostedImageUrls.Mentee_G_4,
      url: hostedImageUrls.Mentee_G_4,
      key: 10,
    },
    {
  
      path: hostedImageUrls.Mentee_G_5,
      url: hostedImageUrls.Mentee_G_5,
      key: 11,
    },
    {
      path: hostedImageUrls.Mentee_G_6,
      url: hostedImageUrls.Mentee_G_6,
      key: 12,
    },
    {
      path: hostedImageUrls.Mentee_M_7,
      url: hostedImageUrls.Mentee_M_7,
      key: 13 ,
    },
  ]

export const interestedAreasOption = [
  "Web Development",
  "Mobile Development",
  "Game Development",
  "Software Design",
  "Data Science",
  "Machine Learning",
  "Artificial Intelligence",
  "Cybersecurity",
  "Cloud Computing",
  "DevOps",
  "Database Management",
  "Embedded Systems",
  "Internet of Things (IoT)",
  "Network Engineering",
  "Blockchain Technology",
  "Augmented Reality (AR)",
  "Virtual Reality (VR)",
  "Robotics",
  "UI/UX Design",
  "Project Management",
  "Quality Assurance (QA)",
  "Automation",
  "Big Data",
  "Business Intelligence",
  "Natural Language Processing (NLP)",
  "Computer Vision",
  "Quantum Computing"
]

export const languagesOptions = [
  "Hindi",
  "English",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
  "Assamese",
  "Maithili",
  "Bhili/Bhilodi",
  "Santali"
];