interface ValidationErrorProps {
    errors:string[],
}

export default function ValidationError(props:ValidationErrorProps){
    const {errors} = props;     // Destructure the props object
    // validation error message
    return(
    <div className="flex flex-col gap-[4px]">
            {errors && errors.map((error, index) => {
                return (
                    <div key={index} className='pt-[2px] pl-[1rem] rounded-[4px] text-red-500 font-medium'>
                        <p className='text-txtRed text-sm'>{error}</p>
                    </div>
                )
            })}
    </div>
    )
}