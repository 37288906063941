import EmailLanding from "../../pages/EmailLanding";
import MenteeStepper from "../../pages/MenteeStepper";
import MentorStepper from "../../pages/MentorStepper";
import Role from "../../pages/Role";
import hostedImageUrls from "../../utils/hosted_image";

interface SignupProps {
  SignupType: string;
}

export default function StepperLayout({ SignupType }: SignupProps) {
  // stepper layout for the signup page
  return (
    <div className="bg-[#6C63FF33]">
      <div className="w-full h-[90px] bg-slate-50 relative bottom-4 pt-[15px]">
      <span style={{
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  height: '60px',
  width: '200px',
  objectFit: 'contain',
  verticalAlign: 'middle'
}}>
           <img
            src={hostedImageUrls.growbinar_logo_full}
            alt="growbinar_logo"
            className=" ml-4"
          />
          </span>
      </div>
      {/* user authentication based and role based stepper */}
      <div className="relative  md:h-[calc(110vh-55px)] h-[calc(165vh-55px)]">
        {SignupType === "EmailSignup" ? <EmailLanding /> :
          (SignupType === "Role") ? <Role /> :
            (SignupType === "MenteeStepper") ? <MenteeStepper /> :
              (SignupType === "MentorStepper") ? <MentorStepper /> : 
              <></>
        }
      </div>
     
    </div>
  );
}
