import  {  useState } from 'react'
import AdditionalDatas from './data.json'
import { Country, State } from 'country-state-city';
import { formStateType, MentorFormError } from '../../utils/MentorUtils';
import ValidationError from '../../components/ValidationError';
import './index.css'
import { AiOutlineClose } from "react-icons/ai";
import hostedImageUrls from '../../utils/hosted_image';

interface MentorProfileProps {
  formState: formStateType;
  updateFormState: Function;
  formError: MentorFormError;
  handleSelectedImage:Function;
  selectedImage:any;
}

export const MentorImage = [
  {
    path: hostedImageUrls.plus_icon,
    url: hostedImageUrls.plus_icon,
    key: 0,
  },
  {
    path: hostedImageUrls.Mentor_M_1,
    url: hostedImageUrls.Mentor_M_1,
    key: 1,
  },
  {
    path: hostedImageUrls.Mentor_M_2,
    url: hostedImageUrls.Mentor_M_2,
    key: 2,
  },
  {
    path: hostedImageUrls.Mentor_M_3,
    url: hostedImageUrls.Mentor_M_3,
    key: 3,
  },
  {
    path: hostedImageUrls.Mentor_G_1,
    url: hostedImageUrls.Mentor_G_1,
    key: 4,
  },
  {
    path: hostedImageUrls.Mentor_G_2,
    url: hostedImageUrls.Mentor_G_2,
    key: 5,
  },
  {
    path: hostedImageUrls.Mentor_G_3,
    url: hostedImageUrls.Mentor_G_3,
    key: 6,
  },
  {
    path: hostedImageUrls.Mentor_M_4,
    url: hostedImageUrls.Mentor_M_4,
    key: 7,
  },
  {
    path: hostedImageUrls.Mentor_M_5,
    url: hostedImageUrls.Mentor_M_5,
    key: 8,
  },
  {
    path: hostedImageUrls.Mentor_M_6,
    url: hostedImageUrls.Mentor_M_6,
    key: 9,
  },

  {
    path: hostedImageUrls.Mentor_G_4,
    url: hostedImageUrls.Mentor_G_4,
    key: 10,
  },
  {
    path: hostedImageUrls.Mentor_G_5,
    url: hostedImageUrls.Mentor_G_5,
    key: 11,
  },
  {
    path: hostedImageUrls.Mentor_G_6,
    url: hostedImageUrls.Mentor_G_6,
    key: 12,
  },
]

export default function MentorStepperProfile({ formState, updateFormState, formError, handleSelectedImage, selectedImage }: MentorProfileProps) {

  const Gender = AdditionalDatas.Gender;
  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState('' as any)
  const [profileImageCon, setProfileImageCon] = useState<boolean>(false);

  // function to handle the country name changes and code changes

  const handleCountryChange = (event: any) => {
    const countryIsoCode = event.target.value;
    if (countryIsoCode === "") {
      updateFormState('country', "")
      updateFormState('city', "")
      setStates([]);
      return
    }
    setCountryCode(countryIsoCode)
    const country: any = Country.getCountryByCode(countryIsoCode);
    updateFormState('country', country['name'])
    setStates(State.getStatesOfCountry(countryIsoCode) as any);
  };

  // function handles the state changes and updates the city name

  const handleStateChange = (event: any) => {
    const stateIsoCode = event.target.value;
    if (stateIsoCode === "" || countryCode === "") {
      updateFormState('city', "")
      return
    }
    const state: any = State.getStateByCodeAndCountry(stateIsoCode, countryCode);
    updateFormState('city', state['name'])
  };

  const handlelImagePopup = () => {
    setProfileImageCon(!profileImageCon)
  }


  return (
    <>
      {profileImageCon && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setProfileImageCon(false)}
        >
          <div
            className="bg-white p-5  rounded shadow-lg relative flex flex-col gap-[16px]"
            onClick={(e) => e.stopPropagation()}
          >
            <h1 className="block text-center text-2xl font-semibold">
              Select your avatar
            </h1>
            {/* <button className='absolute top-[50%] right-[1%]'><FaChevronRight className='text-[16px]' /></button> */}
            <button
              onClick={() => setProfileImageCon(false)}
              className="absolute top-[3%] right-[2%]"
            >
              <AiOutlineClose className="text-[16px]" />
            </button>
            <div className="md:w-[600px] sm:w-[500px] w-[300px] rounded-xl flex flex-col gap-[16px] relative">
              <div className="grid md:grid-cols-5 sm:grid-cols-4 grid-cols-3 gap-[16px] w-full h-[350px] p-[8px] overflow-y-auto no-scrollbar">
                {MentorImage.slice(1).map((image, index) => {
                  return (
                    <>
                      <div
                        className={`mx-auto z-40 ${
                          selectedImage?.key === image.key ? " " : ""
                        }`}
                        onClick={() => handleSelectedImage(image.key)}
                        key={index}
                      >
                        <img
                          src={image?.path}
                          alt="Mentor"
                          className={`w-[80px] h-[80px] rounded-xl z-10 ${
                            selectedImage.key === image.key
                              ? "opacity-70 border-2 border-txtPurple"
                              : ""
                          } duration-200 ease-in hover:scale-105`}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              <div
                className="flex flex-row-reverse "
                onClick={() => setProfileImageCon(false)}
              >
                <button
                  onClick={() => setProfileImageCon(false)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row-reverse"
                >
                  Select
                </button>
              </div>
            </div>

            {/* Your popup content goes here */}
          </div>
        </div>
      )}

      {/* profile input field */}
      <div className="flex flex-col">
        <div className={`flex flex-row gap-[12px] items-center`}>
          <button
            // className={`w-[6.6rem] h-[6.6rem] rounded-full bg-[#A1A6AD] hover:bg-black overflow-hidden ${
            //   formError.profile_picture_url.length > 0
            //     ? "border-2 border-red-500 shake"
            //     : ""
            // }`}
            onClick={handlelImagePopup}
          >
            <img
              className={`w-[5rem] h-[5rem] rounded-full bg-white hover:bg-gray-100 overflow-hidden ${
                formError.profile_picture_url.length > 0
                  ? "border-2 border-red-500 shake"
                  : ""
              }`}
              src={selectedImage?.path}
              alt="profile"
            ></img>
          </button>
          <div className="flex flex-col gap-[8px]">
            <p className="text-txtPurple font-semibold">
              Set a profile picture
            </p>
            <p className="text-txtGrey">select a file</p>
          </div>
        </div>
        <ValidationError errors={formError["profile_picture_url"]} />
      </div>
      <div></div>

      {/* first name input field */}

      <div className="flex flex-col gap-y-[8px]">
        <label htmlFor="" className="text-xl font-CircularStdMedium">
          First Name
        </label>
        <input
          className={`bg-white !opacity-100 md:w-[90%] w-full outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in rounded-[32px] shadow-md p-[12px] ${
            formError.first_name.length > 0
              ? "border-2 border-red-500 shake"
              : ""
          }`}
          onChange={(e) => updateFormState("first_name", e.target.value)}
          placeholder="Enter your name"
          value={formState.first_name}
        ></input>
        <div className="md:w-[90%] w-full">
          <ValidationError errors={formError["first_name"]} />
        </div>
      </div>

      {/* last name input field */}

      <div className="flex flex-col gap-y-[8px]">
        <label htmlFor="" className="text-xl font-CircularStdMedium">
          Last Name
        </label>
        <input
          className="bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in !opacity-100 md:w-[90%] w-full rounded-[32px] shadow-md p-[12px]"
          onChange={(e) => updateFormState("last_name", e.target.value)}
          placeholder="Enter your name"
          value={formState.last_name}
        ></input>
      </div>

      <div className="flex md:justify-between md:flex-row flex-col">
        {/* gender input filed */}

        <div className="md:w-[25%] flex flex-col gap-[8px]">
          <label htmlFor="" className="text-xl font-CircularStdMedium">
            Gender
          </label>
          <select
            className="bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in rounded-[32px] shadow-md p-[12px]"
            onChange={(e) => {
              updateFormState("gender", e.target.value);
            }}
            value={formState.gender}
          >
            {Gender.map((item, index) => {
              return (
                <option className="" value={item} key={index}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>

        {/* phone number input field */}

        <div className="md:w-[70%] flex flex-col gap-y-[8px]">
          <label htmlFor="" className="text-xl font-CircularStdMedium">
            Phone Number
          </label>
          <input
            className={`bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in !opacity-100 md:w-[86%] w-full rounded-[32px] shadow-md p-[12px] ${
              formError.phone_number.length > 0
                ? "border-2 border-red-500 shake"
                : ""
            }`}
            onChange={(e) => updateFormState("phone_number", e.target.value)}
            placeholder="+91 ***** *****"
            value={formState.phone_number}
          ></input>
          <div className="md:w-[90%] w-full">
            <ValidationError errors={formError["phone_number"]} />
          </div>
        </div>
      </div>

      {/* date of birth input field */}

      <div className="flex flex-col gap-[8px]">
        <label htmlFor="dob" className="text-xl font-CircularStdMedium">
          Date of Birth:
        </label>
        <input
          className={`bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in !opacity-100 md:w-[86%] w-full rounded-[32px] shadow-md p-[12px] ${
            formError.date_of_birth.length > 0
              ? "border-2 border-red-500 shake"
              : ""
          }`}
          type="date"
          id="dob"
          value={formState.date_of_birth}
          onChange={(e) => updateFormState("date_of_birth", e.target.value)}
          placeholder=""
          required
          max={new Date().toISOString().split("T")[0]}
        />
        <div className="w-[90%]">
          <ValidationError errors={formError["date_of_birth"]} />
        </div>
      </div>

      {/* country input field */}

      <div className="flex flex-col gap-[8px]">
        <label htmlFor="country" className="text-xl font-CircularStdMedium">
          Country
        </label>
        <select
          id="country"
          className={`bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in !opacity-100 md:w-[86%] w-full rounded-[32px] shadow-md p-[12px] ${
            formError.country.length > 0 ? "border-2 border-red-500 shake" : ""
          }`}
          onChange={handleCountryChange}
          required
        >
          <option value={""}>{formState?.country === "" ? "Select your country":formState?.country}</option>
          {Country.getAllCountries().map((country, index) => {
            return (
              <option
                key={country.isoCode}
                className=""
                value={country.isoCode}
              >
                {country.name}
              </option>
            );
          })}
        </select>
        <div className="w-[90%]">
          <ValidationError errors={formError["country"]} />
        </div>
      </div>

      {/* State input field */}

      <div className="flex flex-col gap-[8px]">
        <label htmlFor="state" className="text-xl font-CircularStdMedium">
          State
        </label>
        <select
          id="state"
          className={`bg-white outline-2  outline-gray-100  focus:outline-txtPurple duration-200 ease-in !opacity-100 md:w-[86%] w-full rounded-[32px] shadow-md p-[12px] ${
            formError.city.length > 0 ? "border-2 border-red-500 shake" : ""
          }`}
          onChange={handleStateChange}
          required
        >
          <option value={""}>{formState?.city === "" ? "Select your state":formState?.city}</option>
          {(states as any[]).map((state, index) => {
            return (
              <option key={state.isoCode} className="" value={state.isoCode}>
                {state.name}
              </option>
            );
          })}
        </select>
        <div className="w-[90%]">
          <ValidationError errors={formError["city"]} />
        </div>
      </div>
    </>
  );
}
