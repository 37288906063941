import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'
import hostedImageUrls from "../../utils/hosted_image";
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(1)
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const resetID = useParams().resetID;
  const [isError, setIsError] = React.useState(false)
  const [isReseted, setIsReseted] = React.useState(false)

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#])[A-Za-z\d@$!%*?&^#]{8,}$/

    const handleResetPassword = async() =>{
      if(password !== confirmPassword){
        return toast.remove();
toast.error('Passwords do not match')
      }
      if(!passwordRegex.test(password)){
        setIsError(true)
        return
      }
      setLoading(0);
      try{
        const response = await axios.post(`${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/change_password/`, {
          password: password,
          code : resetID
        })
        if(response?.status === 200){
          toast.remove();
toast.success('Password reset successful')
          setIsReseted(true);
        }
      }catch(error : any){
        if(error.response?.data?.message === "Code expired"){
          toast.remove();
toast.error('Code expired, please request a new one')
        }
        else{
          toast.remove();
toast.error('An error occurred, please try again')
        }
      }
      setLoading(1);
    }

  

  return (
    <div className='overflow-hidden'>
      <Toaster/>
      <div className="flex flex-row gap-2 items-center justify-end px-4 mt-4">
        <p className="text-base -tracking-[0.064px] font-normal font-CircularStdMedium ">
          Back to login
        </p>
        <button
          className="text-base rounded border-[2px] border-black font-normal font-CircularStdMedium p-1"
          onClick={() => {
            navigate('/login')
          }}
        >
          Login
        </button>
      </div>
      <div className="flex flex-col items-center justify-center min-h-[calc(80vh)]">
        <div>
          <img
            src={hostedImageUrls.growbinar_logo_short}
            alt="growbinar_logo"
            className="w-[150px] h-[110px] flex-shrink-0"
          />
        </div>
        <div className="">
          <p className="text-center sm:text-[36px] text-[20px] font-light font-CircularStdBlack text-[#9F9F9F] whitespace-nowrap">
            Empower your career advancement.
          </p>
        </div>
        {
          isReseted && (
            <>
              <div className="text-green-500">Password reset successful</div>
              <Link to='/login' className=' text-gbpurple text-2xl mt-4 underline'>Login</Link>
            </>
          )
        }
        { !isReseted && <div className="flex flex-col gap-4 p-8 w-full items-center justify-center">
          <input
            type="text"
            value={password}
            placeholder="New Password"
            onChange={(e) => setPassword(e.target.value)}
            className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2"
          />
          <input
            type="text"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="border-[2px] border-black rounded-lg  w-full sm:w-[479px] h-[54px] text-left p-2"
          />
          {isError && (
            <div className='text-red-500 w-full sm:w-[479px]'>The password should contain one uppercase and a lowercase letter one special character and a digit</div>
          )}
          {
              loading === 1 ? <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 items-center justify-center" onClick={()=>{handleResetPassword()}}>
                <p className="text-base font-CircularStdMedium font-normal">
                  Reset
                </p>
              </button>
              :
                <button className="w-full sm:w-[479px] h-[54px] text-white bg-gbpurple rounded-lg gap-4 flex items-center justify-center">

                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                      <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
                    </path>
                  </svg>

                </button>
            }
      </div>}
      </div>
    </div>
  )
}

export default ResetPassword