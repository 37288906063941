import locationMarker from "../../assets/images/location-marker.svg";
import globe from "../../assets/images/globe.svg";
import briefcase from "../../assets/images/briefcase.svg";
import "./index.css";
import MentorBackgroundDetailsCard from "../../components/MentorBackgroundDetailsCard/MentorBackgroundDetailsCard";
import MentorExperienceCard from "../../components/MentorExperienceCard/MentorExperienceCard";
import AvailableSession from "../../components/AvailableSession/AvailableSession";
import MentorCardsContainer from "../MentorCardsContainer/MentorCardsContainer";
import { RiVolumeUpFill } from "react-icons/ri";
import { FcLike } from "react-icons/fc";
import { FaRegMessage } from "react-icons/fa6";
import { TbCoinRupeeFilled } from "react-icons/tb";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AddExperiencePopUp from "../../components/AddExperience";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ThreedotLoader from "../../components/ThreedotLoader";
import {
  convertToAMPM,
  getMonthDateFromDate,
} from "../../utils/HelperFunctions";
import toast from "react-hot-toast";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import MentorProfileEdit from "../MentorProfileEdit";
import { AuthContext } from "../../contexts/AuthContext";
import { MdOutlineAddCircleOutline } from "react-icons/md";

interface background {
  fluency: string[];
  expertise: string[];
}

interface MentorProfileDetailResponse {
  name: string;
  organisation: string;
  overview: string;
  location: string;
  background: background;
  designation: string;
  "Available-Sessions": avaliableDateTimeObj[];
  profile_image_url: string;
  years_of_experience: number;
}

interface avaliableDateTimeObj {
  to: string;
  from: string;
  date: string;
}

export interface MentorExpericence {
  designation: string;
  organization: string;
  description: string;
  startDate: string;
  endDate: string;
  is_education?: boolean;
}

//mui
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function MentorProfile(props: any) {
  const [profileData, setProfileData] = useState<MentorProfileDetailResponse>({
    name: "",
    organisation: "",
    overview: "",
    location: "",
    background: {
      fluency: [],
      expertise: [],
    },
    designation: "",
    "Available-Sessions": [],
    profile_image_url: "",
    years_of_experience: 0,
  });

  // if the user is visiting his own profile then userProfilePath is true
  const [MentorExpericence, setMentorExpericence] = useState<
    MentorExpericence[]
  >([]);
  const [experience, setExperience] = useState<MentorExpericence>({
    designation: "",
    organization: "",
    description: "",
    startDate: "",
    endDate: "",
    is_education:true,
  });
  const [isError, setIsError] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [popup, setPopup] = useState(false);
  const { userId } = useParams();
  const [loader, setLoader] = useState<boolean>(false);
  const { setUserProfile } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/mentor_details/?id=${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
            },
          }
        );
        setProfileData(response?.data?.data);
        setUserProfile(response?.data?.data);
        setMentorExpericence(response?.data?.data?.experience);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (profileData.name === "") {
    return (
      <>
        <div className="flex justify-center items-center w-full h-full fixed inset-0">
          <ThreedotLoader />
        </div>
      </>
    );
  }

  const handleAddExperience = async () => {
    setLoader(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GROWBINAR_BACKEND_URL}/profile/experience/`,
        {
          role: experience?.designation,
          company: experience?.organization,
          description: experience?.description,
          from_duration: experience?.startDate,
          to_duration: experience?.endDate,
          is_education: experience?.is_education,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("GB_ACCESS_TOKEN")}`,
          },
        }
      );
      if (response?.status === 200) {
        setMentorExpericence([...MentorExpericence, experience]);
        setPopup(false);
        setExperience({
          designation: "",
          organization: "",
          description: "",
          startDate: "",
          endDate: "",
        });
      }
    } catch (error) {
      toast.remove();
toast.error("Could not add experience, please try again later");
      console.log(error);
    }
    setLoader(false);
  };

  if (isError) {
    return (
      <div className="h-full w-full flex justify-center items-center text-2xl font-CircularStdBold">
        <div>user not found</div>
      </div>
    );
  }

  return (
    <>
      {editProfile && (
        <MentorProfileEdit
          closeEditPopUp={setEditProfile}
          displayToastMessage={props.displayToastMessage}
        />
      )}
      <div
        className={`${
          editProfile ? "hidden" : "flex flex-col gap-[20px] relative"
        }`}
      >
        <div className="bg-cover-container"></div>
        <div className="px-4 relative flex flex-col items-center gap-[10px] mt-[20px]">
          <div className="absolute -top-[150px]">
            <span
              className="inline-block rounded-full object-contain overflow-hidden  border-white
                    md:h-[200px] md:w-[200px] md:border-[10px] 
                    sm:h-[180px] sm:w-[180px] sm:border-[9px]
                    h-[160px] w-[160px] border-[8px] z-10"
            >
              <img src={profileData?.profile_image_url} alt="profilepic" />
            </span>
            <BootstrapTooltip title="Coming Soon" className="z-100">
              <div className="flex gap-2 items-center hover:opacity-80 interaction-container">
                <RiVolumeUpFill className="absolute flex items-center justify-center rounded-full md:text-[40px] text-[32px] p-1 text-txtPurple bg-[#f0effe] bottom-[10px] right-[35px]" />
              </div>
            </BootstrapTooltip>
          </div>
        </div>
        <div className="px-[16px] flex flex-col gap-[8px]">
          <div className="md:mt-[0px] mt-[16px] sm:pr-10 flex justify-center md:justify-end w-full gap-[12px]">
            <BootstrapTooltip title="Coming Soon">
              <div className="flex gap-2 items-center hover:opacity-50 interaction-container">
                <div className=" p-[8px]">
                  <FcLike className="text-[1.5rem]" />
                </div>
                <div className=" p-[8px]">
                  <FaRegMessage className="text-[1.1rem]" />
                </div>
                <div className="flex items-center gap-[8px] bg-black text-white px-[8px] py-[16px] rounded-xl font-medium leading-[19px]">
                  <TbCoinRupeeFilled className="text-[1.1rem] text-yellow-300" />
                  <span className="font- ">Appreciate</span>
                </div>
              </div>
            </BootstrapTooltip>

            <button
              className="px-4 py-2 mx-4 bg-gbpurple text-white rounded-lg font-CircularStdMedium"
              onClick={() => {
                setEditProfile(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="flex flex-col items-center justify-center gap-[8px]">
            <p className="text-[1.8rem] font-medium"> {profileData?.name}</p>
            <p className="text-[1.2rem] font-normal text-gray-600">
              {" "}
              {/* {profileData?.organisation} */}
              {profileData?.designation}{" "}
              <span className="text-[#A1A6AD]">at </span>
              {profileData?.organisation}
            </p>
            <div className="flex flex-col md:flex-row items-center mb-5 gap-[20px] text-[#636b78] overflow-hidden">
              <div className="flex flex-row items-center w-auto gap-[8px]">
                <span>
                  {" "}
                  <img src={locationMarker} alt="location-marker" />
                </span>
                <p>{profileData?.location}</p>
              </div>

              <div className="flex flex-row items-center w-auto gap-[8px]">
                <span>
                  {" "}
                  <img src={globe} alt="location-marker" />
                </span>
                <p>
                  {profileData?.background.fluency.map((item) => {
                    return item + " ";
                  })}
                </p>
              </div>

              <div className="flex flex-row items-center w-auto gap-[8px]">
                <span>
                  {" "}
                  <img src={briefcase} alt="location-marker" />
                </span>
                <p>
                  {profileData.years_of_experience === 0 ||
                  !profileData.years_of_experience
                    ? "Fresher"
                    : profileData.years_of_experience === 1
                    ? `${profileData.years_of_experience} year of experience`
                    : `${profileData.years_of_experience} years of Experience`}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center rounded-[10px] p-[8px] gap-[16px] shadow-md hover:shadow-sm duration-200">
            <div className="text-start sm:text-center flex gap-[8px] flex-col sm:flex-row">
              <div className="flex flex-row gap-[4px]">
                {profileData["Available-Sessions"][0]?.date === undefined ? (
                  <p className="text-[1rem] font-semibold">
                    {"No Sessions Available"}
                  </p>
                ) : (
                  <>
                    <div className="flex flex-row gap-[4px] px-[16px] py-[8px] items-center">
                      <p className="text-[1rem] font-semibold">
                        {"Next Available"}
                      </p>
                      <span className="text-[#10de16]">{` ${getMonthDateFromDate(
                        profileData["Available-Sessions"][0]?.date
                      )} | ${convertToAMPM(
                        profileData["Available-Sessions"][0]?.from
                      )}`}</span>
                    </div>
                  </>
                )}{" "}
              </div>
            </div>
            {/* <button className="text-[1.2rem] font-medium text-[#f9f9fa] bg-[#6c63ff] rounded-[8px] px-[16px] py-[8px]">Book</button> */}
          </div>
          <div className="flex flex-col mt-5 lg:flex-row gap-[16px] justify-center items-center md:items-start overview-experience-and-availabilities">
            {/* left side of profile */}

            <div className="w-full flex flex-col gap-[16px] lg:w-[48%]  md:leading-[40px] sm:leading-[30px] leading-[20px]">
              {/* overview about the user */}

              <div className="flex flex-col min-h-[180px] sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                <h3 className="text-2xl font-semibold">Overview</h3>
                <div className="justify-bez`tween text-gray-600 font-medium">
                  {profileData?.overview}
                </div>
              </div>

              {/* Background of the user */}
              <div className="flex flex-col min-h-[180px] sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                <h3 className="text-2xl font-semibold">Background</h3>
                <div className="flex flex-col">
                  <MentorBackgroundDetailsCard
                    experience={profileData?.background?.expertise}
                    fluency={profileData?.background?.fluency}
                  />
                </div>
              </div>
              {/* education */}
              <div className="w-full flex justify-center md:justify-start items-center">
                <div className="flex flex-col min-h-[180px] w-full sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold">Education</h3>
                    <button
                      className="text-2xl font-semibold rounded-full p-[8px] hover:bg-gray-300"
                      onClick={() => {
                        setExperience({
                          ...experience,
                          is_education: true,
                        });
                        setPopup(true);
                      }}
                    >
                      <MdOutlineAddCircleOutline />
                    </button>
                  </div>
                  <div className="flex flex-col gap-8 content">
                    {
                      // eslint-disable-next-line array-callback-return
                      MentorExpericence.map((item, index) => {
                        if (item.is_education === true) {
                          return (
                            <MentorExperienceCard
                              designation={item.designation}
                              organization={item.organization}
                              description={item.description}
                              startDate={item.startDate}
                              endDate={item.endDate}
                              key={index}
                            />
                          );
                        }
                      })
                    }
                  </div>
                </div>
              </div>
              {/* experience */}
              <div className="w-full flex justify-center md:justify-start items-center">
                <div className="flex flex-col min-h-[180px] w-full sm:gap-[16px] gap-[8px] sm:p-[16px] p-[12px] rounded-[16px] border border-[#d1d4d6]">
                  <div className="flex items-center justify-between">
                    <h3 className="text-2xl font-semibold">Experience</h3>
                    <button
                      className="text-2xl font-semibold rounded-full p-[8px] hover:bg-gray-300"
                      onClick={() => {
                        setExperience({
                          ...experience,
                          is_education: false,
                        });
                        setPopup(true);
                      }}
                    >
                      <MdOutlineAddCircleOutline />
                    </button>
                  </div>
                  <div className="flex flex-col gap-8 content">
                    {
                      // eslint-disable-next-line array-callback-return
                      MentorExpericence.map((item, index) => {
                        if (item.is_education === false) {
                          return (
                            <MentorExperienceCard
                              designation={item.designation}
                              organization={item.organization}
                              description={item.description}
                              startDate={item.startDate}
                              endDate={item.endDate}
                              key={index}
                            />
                          );
                        }
                      })
                    }
                  </div>
                </div>
              </div>
              {/* add a popup form that collects the data.. organization role and description */}
              <div
                className={`${
                  popup ? "block" : "hidden"
                } fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 transform duration-500 ease-in-out`}
                onClick={() => {
                  setExperience({
                    designation: "",
                    organization: "",
                    description: "",
                    startDate: "",
                    endDate: "",
                  });
                  setPopup(false);
                }}
              >
                <AddExperiencePopUp
                  setPopup={setPopup}
                  experience={experience}
                  setExperience={setExperience}
                  handleAddExperience={handleAddExperience}
                  loader={loader}
                />
              </div>
            </div>

            {/* Right side of the profile */}

            <div className="w-full flex flex-col lg:w-[48%]  md:leading-[40px] sm:leading-[30px] leading-[20px]">
              <div className="flex gap-[16px] flex-col">
                {/* availblesession data */}
                <AvailableSession
                  data={profileData["Available-Sessions"]}
                  mentor_id={userId}
                  view_state={"private"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
