interface HostedImageUrl {
  growbinar_logo_full: string;
  growbinar_logo_short: string;

  books_piled: string;
  learning_boy: string;
  learning_girl: string;
  group_disc: string;
  video_frame: string;
  professional_development: string;
  exclusive_mentor_community: string;
  flexible_scheduling: string;
  signin_character: string;
  signup_character: string;
  contactus: string;
  email_bird: string;
  love_emoji: string;
  mentor_arrow: string;
  top_rated: string;
  browse_mentors_post: string;
  signup_mentor: string;
  signup_mentee: string;
  waiting_cursor: string;

  landing_mentor1: string;
  landing_mentor2: string;
  landing_mentor3: string;
  landing_mentor4: string;
  landing_mentor5: string;
  landing_mentor6: string;

  news_letter_left_image: string;
  news_letter_right_image: string;

  linkedin_logo: string;
  instagram_logo: string;
  group_mentors: string;
  our_teams_linkedin: string;
  google_logo: string;

  globe_image: string;
  data_science: string;
  design: string;
  engg_image: string;
  marketing: string;

  mail_icon: string;
  plus_icon: string;
  blankprofile: string;
  quote_icon: string;

  unauth: string;
  waitingcursor: string;

  Mentor_M_1: string;
  Mentor_M_2: string;
  Mentor_M_3: string;
  Mentor_M_4: string;
  Mentor_M_5: string;
  Mentor_M_6: string;
  Mentor_G_1: string;
  Mentor_G_2: string;
  Mentor_G_3: string;
  Mentor_G_4: string;
  Mentor_G_5: string;
  Mentor_G_6: string;

  Mentee_M_1: string;
  Mentee_M_2: string;
  Mentee_M_3: string;
  Mentee_M_4: string;
  Mentee_M_5: string;
  Mentee_M_6: string;
  Mentee_M_7: string;
  Mentee_G_1: string;
  Mentee_G_2: string;
  Mentee_G_3: string;
  Mentee_G_4: string;
  Mentee_G_5: string;
  Mentee_G_6: string;
}

// contains the data of the hosted images

const hostedImageUrls: HostedImageUrl = {
  growbinar_logo_full:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Grow_logo_full.png",
  growbinar_logo_short:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Grow_logo_short.png",

  books_piled:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/books_piled.png",
  learning_boy:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor_landing.png",
  learning_girl:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Learning_girl.png",
  group_disc:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/group_disc.png",
  video_frame:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/promotion.mp4",
  professional_development:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Professional%20Development.png",
  exclusive_mentor_community:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Exclusive%20Mentor%20Community.png",
  flexible_scheduling:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Flexible%20Scheduling.png",
  signin_character:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/signin_character.png",
  signup_character:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/SignupImage.png",
  contactus:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/Contactus.png",
  email_bird:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/bird.png",
  love_emoji:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/love-emoji.png",
  mentor_arrow:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor_arrow.png",
  top_rated:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/top-rated-mentor.png",
  browse_mentors_post:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/browse-mentors-post.png",
  signup_mentee:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/signup_student.png",
  signup_mentor:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/signup_mentor.png",
  waiting_cursor:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/waitingcursor.png",

  landing_mentor1:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor1.jpg",
  landing_mentor2:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor2.jpg",
  landing_mentor3:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor3.jpg",
  landing_mentor4:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor4.jpg",
  landing_mentor5:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor5.jpg",
  landing_mentor6:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mentor6.jpg",

  news_letter_left_image:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/news_letter_left_image%20.png",
  news_letter_right_image:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/news_letter_right_image.png",

  linkedin_logo:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/linkedin.png",
  instagram_logo:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/instagram.png",
  our_teams_linkedin:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/linkedin_logos.png",
  google_logo:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/google_icon.png",

  group_mentors:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/group_mentors.png",

  globe_image:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/globe_image.png",
  data_science:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/data_science.png",
  design:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/design.png",
  engg_image:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/engg.png",
  marketing:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/marketing.png",

  mail_icon:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/mail_image.png",
  plus_icon:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/icons8-plus-50.png",
  quote_icon:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/quote_icon.png",
  blankprofile:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/blankprofile.jpg",

  unauth:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/unauth.png",
  waitingcursor:
    "https://gb-static-files.sirv.com/Frontend-Static/general_assets/quote_icon.png",

  Mentor_M_1:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_1.png",
  Mentor_M_2:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_2.png",
  Mentor_M_3:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_3.png",
  Mentor_M_4:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_4.png",
  Mentor_M_5:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_5.png",
  Mentor_M_6:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_M_6.png",
  Mentor_G_1:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_1.png",
  Mentor_G_2:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_2.png",
  Mentor_G_3:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_3.png",
  Mentor_G_4:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_4.png",
  Mentor_G_5:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_5.png",
  Mentor_G_6:
    "https://gb-static-files.sirv.com/Frontend-Static/mentor_profile/Mentor_G_6.png",

  Mentee_G_1:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_1.png",
  Mentee_G_2:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_2.png",
  Mentee_G_3:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_3.png",
  Mentee_G_4:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_4.png",
  Mentee_G_5:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_5.png",
  Mentee_G_6:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_G_6.png",
  Mentee_M_1:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_1.png",
  Mentee_M_2:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_2.png",
  Mentee_M_3:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_3.png",
  Mentee_M_4:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_4.png",
  Mentee_M_5:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_5.png",
  Mentee_M_6:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_6.png",
  Mentee_M_7:
    "https://gb-static-files.sirv.com/Frontend-Static/mentee_profile/Mentee_M_7.png",
};

export default hostedImageUrls;
