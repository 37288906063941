import "./index.css";
import { useState } from "react";
import hostedImageUrls from "../../utils/hosted_image";
import data from "./data.json";

interface Mentor {      // Define the types of the mentor on the testimonial
  name: string;
  role: string;
  profile: string;
  organisation: string;
}

interface Mentee {    // Define the types of the mentee on the testimonial
  name: string;
  role: string;
  profile: string;
}

interface TestimonialContent {    // Define the types of the testimonial content
  mentor: Mentor;
  mentee: Mentee;
  content: string;
}

export default function TestmonialContainer() {
  // testimonial container to show the testimonial of the mentee
  const [testimonialData] = useState<TestimonialContent[] | null>(data);

  return (
    <div className="flex sm:flex-row sm:flex-nowrap flex-col  gap-[16px] items-center justify- sm:space-x-5 p-2">
      {/* main content section  */}
      { testimonialData && testimonialData.map((data, index) => (
        <div className="flex flex-col gap-[12px] sm:w-[442px] w-[90%] mx-auto  sm:h-[425px] rounded-3xl flex-shrink-0 border border-gbpurple items-start justify-start sm:p-[30px] p-[16px]" key = {index}>
          <div className="flex items-center justify-center space-x-5">
            <img
              src={data?.mentee?.profile}
              alt="test_image"
              className="w-[60px] h-[60px] rounded-full"
              style={{ objectFit: 'cover', objectPosition: 'center 20%' }}
            />
            <div className="flex flex-col space-y-2">
              <div className="w-auto h-[22px] font-CircularStdMedium text-[16px] font-medium ">
                {data?.mentee?.name}
              </div>
              <div className="font-CircularStdMedium font-medium text-[14px] h-[19px] text-[#9F9F9F]">
                {data?.mentee?.role}
              </div>
            </div>
          </div>

          {/* block quote section  */}
          <div className="mt-2">
            <img src={hostedImageUrls.quote_icon} alt="quote icon" />
            <div className="sm:w-[248px] h-[115px]">
              <p className="test-content">{data?.content}</p>
            </div>
          </div>

          {/* </blockquote> */}

          {/* mentor arrow image */}
          <div className="mentor_arrow ">
            <img src={hostedImageUrls?.mentor_arrow} alt="mentor_arrow" />
          </div>

          {/* mentor name section  */}
          <div className="sm:w-[349px] w-full h-[72px] flex-shrink-0 rounded-[211px] bg-gbpurple">
            <div className="flex items-center justify-start h-full space-x-3">
              <img src={data?.mentor?.profile} alt="test_mentor_profile" className="h-[60px] w-[60px] rounded-full ml-2" style={{ objectFit: 'cover', objectPosition: 'center 20%' }} />
              <div className="flex flex-col ">
                <div className="h-[24px] font-CircularStdMedium text-[16px] font-medium text-[#fff]">
                  {data?.mentor?.name}
                </div>
                <div className="font-CircularStdMedium font-medium text-[16px] w-auto h-[24px] text-[#fff]">
                  {data?.mentor?.role}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
