import "./index.css";
import Companies from "./Companies";
import Marquee from "react-fast-marquee";
import TestmonialContainer from "../../containers/TestimonialContainer";
import LandingPageMentors from "../../containers/LandingPageMentors";
import MentorHero from "../HeroSections/MentorHero";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import hostedImageUrls from "../../utils/hosted_image";

// Mentor Landing page
export default function MentorLandingPage() {
  const { authed } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <div>
      <MentorHero />
      {/* companies logo section  */}
      {/* left to right marquee */}

      <Marquee direction="left" speed={200} className="mt-[100px]">
        <div className="companies-container">
          {Companies?.map((value, index) => (
            <div className="gap-[70px] items-center justify-start " key={index}>
              <img
                className="h-[42px] w-[193px] flex-shrink-0"
                src={Companies[index]?.logo}
                alt="companies_logo"
              />
            </div>
          ))}
        </div>
      </Marquee>
      {/* right to left marquee */}
      <Marquee direction="right" speed={200} className="marquee-container">
        <div className="companies-container">
          {Companies.map((value, index) => (
            <div
              className="gap-[70px] items-center justify-center "
              key={index}
            >
              <img
                className="h-[42px] w-[193px] flex-shrink-0"
                src={Companies[index].logo1}
                alt="companies_logo"
              />
            </div>
          ))}
        </div>
      </Marquee>

      <div className="flex flex-col items-center justify-center sm:mt-[80px] mt-[40px]">
        <div className="flex p-3  items-center gap-[10px] rounded-full bg-[#F2F1FF]">
          <p className="text-gbpurple text-center text-[16px] not-italic font-semibold leading-6">
            WHY MENTOR WITH GROWBINAR
          </p>
        </div>
        <br />
        <div className="">
          <p className="text-center lg:text-[48px] md:text-[34px] text-[28px] not-italic font-CircularStdBold sm:leading-[64px] leading-[40px] text-black">
            Share Your Expertise and{" "}
          </p>
          <p className="text-center lg:text-[48px] md:text-[34px] text-[28px] not-italic font-CircularStdBold sm:leading-[64px] leading-[40px] text-black ">
            Shape Futures!
          </p>
        </div>
      </div>

      {/* video section */}
      <iframe
        src={hostedImageUrls.video_frame}
        className="flex lg:w-[994px] md:w-[720px] sm:w-[550px] w-[90%] lg:h-[623px] md:h-[550px] h-[300px] sm:h-[440px] mx-auto rounded-[32px] object-contain bg-cover sm:mt-[50px] mt-[25px]"
      />

      {/* key features of growbinar section */}
      <div className="w-full mt-[50px] flex flex-col gap-[16px]">
        {/* first key features row section */}
        <div className="flex lg:flex-row flex-col gap-[16px] justify-between sm:w-[82%] w-[90%] mx-auto items-center">
          {/* Impactful Mentorship */}
          <div className="lg:w-[57%] md:h-[379.1px] sm:h-[250px] h-[230px] w-full rounded-[18px] border-2 border-gray-300 lg:pl-[35px] md:pl-[16px] md:p-0 p-[16px]">
            <div className="flex justify-start">
              <p className="text-[#9f9f9f] text-center lg:text-[1.1rem] md:text-[1rem] font-medium md:leading-[70.618px] sm:leading-[50px]">
                IMPACTFUL MENTORSHIP
              </p>
            </div>

            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="lg:mt-[50px] md:mt-[40px] sm:mt-[24px] mt-[12px]">
                <div className="flex flex-col justify-start w-full">
                  <div className="text-black font-CircularStdMedium lg:text-[1.5rem] sm:text-[1.4rem] text-[1.2rem] sm:leading-[34.205px] tracking-[0.265px]">
                    <p>
                      Make a significant difference in students' lives by
                      sharing your knowledge.
                    </p>
                  </div>
                </div>
                <div className="flex m-w-[387.293px] max-h-[90.479px] flex-col items-start  mt-[5px]">
                  <div className="text-[#7f7f7f] font-CircularStdBold md:text-[1rem]  sm:text-[0.9rem] text-[0.8rem] font-medium sm:leading-[26.5px] leading-[20px]">
                    <p>
                      Your guidance can shape futures and create lasting impact.
                    </p>
                  </div>
                </div>
              </div>
              <img
                src={hostedImageUrls.learning_boy}
                alt="pileof-books"
                className="md:max-w-[264px] min-h-[285px] mt-[18px] place-self-end rounded-r-[18px] md:inline-block hidden"
              />
            </div>
          </div>
          {/* Mentor Community */}
          <div className="lg:max-w-[40%]  flex flex-col md:h-[379.1px] sm:h-[250px] h-[230px] md:gap-y-[20px] gap-y-[10px] w-full rounded-[18px] border-2 border-gray-300 md:pl-[35px] md:p-0 p-[16px]">
            <div className="flex md:overflow-hidden h-[50%]">
              <div className="flex flex-row justify-start flex-1">
                <p className="text-[#9f9f9f] text-center lg:text-[1.1rem] md:text-[1rem] font-medium md:leading-[70.618px] sm:leading-[40px]">
                  MENTOR COMMUNITY
                </p>
              </div>
              <img
                src={hostedImageUrls.exclusive_mentor_community}
                className="md:max-w-[264px] md:inline-block hidden  min-h-[181px] rounded-r-[18px] "
                alt="group"
              />
            </div>
            <div className="flex flex-col">
              <div className="text-black font-CircularStdMedium lg:text-[1.6rem] md:text-[1.5rem] sm:text-[1.4rem] text-[1.2rem] font-medium sm:leading-[34.205px] tracking-[0.265px]">
                <p>
                  Join the top 1% of mentors in Growbinar's elite community.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start ">
              <div className="text-[#7f7f7f] font-CircularStdBold md:text-[1rem]  sm:text-[0.9rem] text-[0.8rem] text-left font-medium md:leading-[26.482px] leading-[20px]">
                <p>
                  Collaborate, share insights, and grow alongside other
                  distinguished mentors dedicated to excellence.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* second row for key features */}

        <div className="flex lg:flex-row flex-col gap-[16px] justify-between sm:w-[82%] w-[90%] mx-auto items-center">
          {/* Professional Development Feature */}
          <div className="lg:max-w-[40%]  w-full lg:h-[376px] md:h-[371.1px] sm:h-[250px] rounded-[18px] border-2 border-gray-300 md:pl-[35px] p-[16px] md:p-0 flex flex-col gap-[12px]">
            <div className="flex justify-between h-[50%]">
              <div className="flex  justify-start">
                <p className="text-[#9f9f9f] text-center lg:text-[1rem] md:text-[1rem] font-medium md:leading-[70.618px] sm:leading-[40px]">
                  PROFESSIONAL DEVELOPMENT
                </p>
              </div>
              <img
                src={hostedImageUrls.professional_development}
                alt="piled_books"
                className="rounded-r-[18px] md:inline-block hidden"
              />
            </div>
            <div className="flex flex-col items-start">
              <div className="flex-1 text-black font-CircularStdMedium lg:text-[1.6rem] md:text-[1.5rem] sm:text-[1.4rem] text-[1.2rem] text-left font-medium leading-[34.205px] tracking-[0.265px]">
                <p>Stay competitive through </p>
                <p>professional growth.</p>
              </div>
            </div>
            <div className="flex lg:w-[368.535px] flex-col">
              <div className="text-[#7f7f7f] font-CircularStdBold md:text-[1rem]  sm:text-[0.9rem] text-[0.8rem] text-left font-medium md:leading-[26.482px] leading-[20px]">
                <p>
                  Enhance your mentoring skills with Growbinar’s continuous
                  professional development resources and training.
                </p>
              </div>
            </div>
          </div>
          {/* Flexible Scheduling Features */}
          <div className="lg:max-w-[57%] w-full lg:h-[376px] md:h-[371.1px]  flex flex-col">
            <div className="rounded-[18px] border-2 border-gray-300 md:pl-[35px] p-[16px] md:p-0 md:h-auto sm:h-[250px]">
              <div className="flex justify-start">
                <p className="text-[#9f9f9f] text-center lg:text-[1.1rem] md:text-[1rem] font-medium md:leading-[70.618px] sm:leading-[40px]">
                  FLEXIBLE SCHEDULING
                </p>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="flex flex-col gap-[16px] lg:mt-0 mt-[12px]">
                  <div className="flex lg:w-[404.948px] w-full flex-col text-left">
                    <div className="text-black font-CircularStdMedium lg:text-[1.6rem] md:text-[1.5rem] sm:text-[1.4rem] text-[1.2rem] leading-[34.205px] tracking-[0.265px]">
                      <p>Optimize your time by focusing </p>
                      <p>on essential tasks and resources.</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <div className="text-[#7f7f7f] font-CircularStdBold md:text-[1rem]  sm:text-[0.9rem] text-[0.8rem] text-left font-medium md:leading-[26.482px] leading-[20px]">
                      <p>
                        Enjoy the freedom to set your own schedule and manage
                        sessions at your convenience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-end mt-4">
                  <img
                    src={hostedImageUrls.flexible_scheduling}
                    alt="learning-girl"
                    className="h-[180px] rounded-2xl md:inline-block hidden"
                  />
                </div>
              </div>
            </div>

            {/* explore mentors button */}
            <div className="w-full md:p-[16px] p-[8px] rounded-[15.448px] border-[1.65px] border-[#6c60fe] bg-[#6c60fe] text-center mt-[29px]">
              <div className="flex self-center md:justify-between md:flex-row flex-col items-center md:gap-[12px] gap-[16px]">
                <div className="text-white font-CircularStdMedium lg:text-[1.2rem] md:text-[1.1rem] text-[1rem] font-medium text-center">
                  Join our mentor team and make a difference
                </div>
                <button
                  className="explore-button hover:opacity-85 ease-in duration-100"
                  onClick={() => {
                    if (authed?.authed === true) {
                      navigate(`/${authed?.role}/home`);
                    } else {
                      navigate(`/signup`);
                    }
                  }}
                >
                  Explore
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* find your mentors section */}
      <div className="flex flex-col items-center justify-center mt-[100px]">
        <div className="inline-flex w-[208px] h-[51px] p-2 items-center justify-center rounded-[211px] bg-[#F2F1FF]">
          <p className="mentor-text">EXPLORE</p>
        </div>
        <br />
        <div className="h-auto  whitespace-nowrap text-center">
          <p className="text-black font-CircularStdMedium lg:text-[3rem] md:text-[2.2rem] sm:text-[1.8rem] text-[1.6rem]  font-medium p-[10px] md:leading-[65px] leading-[40px]">
            MEET OUR MENTORS
          </p>
          <p className="md:text-2xl text-xl font-semibold leading-8">
            Expertise Unleashed
          </p>
        </div>
      </div>

      {/* filter button section  */}
      <div className="flex   flex-row flex-wrap items-center justify-center gap-[24px] mt-[25px]">
        <button className="flex px-4 py-3 items-center justify-center gap-2 bg-black cursor-pointer rounded-[12px]">
          <img
            src={hostedImageUrls.globe_image}
            className="md:w-[40px] w-[30px] md:h-[40px] h-[30px]"
            alt="globe"
          />
          <p className="text-white flex items-center font-CircularStdMedium md:text-[24px] text-[16px] font-medium">
            All
          </p>
        </button>
        <button className="flex px-4 py-3 items-center justify-center gap-2 bg-[#F2F2F7] cursor-pointer rounded-[12px]">
          <img
            src={hostedImageUrls.engg_image}
            className="md:w-[40px] w-[30px] md:h-[40px] h-[30px]"
            alt="engineering"
          />
          <p className="text-black text-center md:text-[24px] text-[16px] font-CircularStdMediumn font-medium">
            Engineering
          </p>
        </button>
        <button className="flex px-4 py-3 items-center justify-center gap-2 bg-[#F2F2F7] cursor-pointer rounded-[12px]">
          <img
            src={hostedImageUrls.marketing}
            className="md:w-[40px] w-[30px] md:h-[40px] h-[30px]"
            alt="marketing"
          />
          <p className="text-black text-center md:text-[24px] text-[16px] font-CircularStdMediumn font-medium">
            Marketing
          </p>
        </button>
        <button className="flex px-4 py-3 items-center justify-center gap-2 bg-[#F2F2F7] cursor-pointer rounded-[12px]">
          <img
            src={hostedImageUrls.design}
            className="md:w-[40px] w-[30px] md:h-[40px] h-[30px]"
            alt="design"
          />
          <p className="text-black text-center md:text-[24px] text-[16px] font-CircularStdMediumn font-medium">
            Design
          </p>
        </button>
        <button className="flex px-4 py-3 items-center justify-center gap-2 bg-[#F2F2F7] cursor-pointer rounded-[12px]">
          <img
            src={hostedImageUrls.data_science}
            className="md:w-[40px] w-[30px] md:h-[40px] h-[30px]"
            alt="data_science"
          />
          <p className="text-black text-center md:text-[24px] text-[16px] font-CircularStdMediumn font-medium">
            Data Science
          </p>
        </button>
      </div>
      <br />

      {/* mentors card section */}
      <LandingPageMentors />

      {/* user client section */}
      <div className="flex flex-col w-full bg-[#F7F8FF] items-center justify-center space-y-8 mt-[75px] py-[70px]">
        <div className="p-[15px] bg-[#DDDAFF] text-center text-[17px] font-CircularStdMedium font-normal text-gbpurple rounded-[211px]">
          WHAT WE DO
        </div>
        <div className=" lg:w-[995px] lg:text-[48px] text-[38px] font-CircularStdBold lg:leading-[63px] text-center flex-shrink-0 whitespace-pre-line p-3">
          A Results-Driven Platform Empowering Success
        </div>

        <div className="flex lg:flex-row flex-col lg:gap-[4rem] gap-8 lg:pt-[60px] m-4">
          <div className="flex flex-col items-center justify-center">
            <p className="font-CircularStdMedium text-[48px] lg:leading-[64px] text-center">
              95%
            </p>
            <p className="text-[30px] lg:leading-[64px] font-normal ">
              Satisfied Members
            </p>
          </div>

          <div className="hidden lg:block divider"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="font-CircularStdMedium text-[48px] leading-[64px] text-center">
              500+
            </p>
            <p className="text-[30px] leading-[64px] font-normal ">
              Expert Mentors
            </p>
          </div>

          <div className="hidden lg:block divider"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="font-CircularStdMedium text-[48px] leading-[64px] text-center">
              15+
            </p>
            <p className="text-[30px] leading-[64px] font-normal ">
              States Reached
            </p>
          </div>

          <div className="hidden lg:block divider"></div>

          <div className="flex flex-col items-center justify-center">
            <p className="font-CircularStdMedium text-[48px] leading-[64px] text-center">
              4500+
            </p>
            <p className="text-[30px] leading-[64px] font-normal ">
              Connections Built
            </p>
          </div>
        </div>
      </div>

      {/* Testimonial section  */}

      <div className="text-black text-center font-CircularStdMedium lg:text-[42px] sm:text-[34px] text-[30px] mt-[  12px] font-bold leading-[63px] tracking-[0.54px]">
        Community Favored and Treasured.
      </div>

      {/* testimonial marquee section */}
      <div className="mt-[35px] sm:block hidden">
        <Marquee direction="left" speed={50} className="">
          <TestmonialContainer />
        </Marquee>
      </div>
      <div className="mt-[35px] sm:hidden block">
        <TestmonialContainer />
      </div>
      {/* newsletter section */}
      <div className="w-full h-[550px] flex items-center justify-center flex-shrink-0 bg-[#F7F8FF] mt-[100px] mb-4 overflow-hidden">
        <div className="grid grid-flow-col items-center justify-center">
          <div className="lg:flex w-[412px] h-[438px] items-center justify-center hidden">
            <img
              src={hostedImageUrls.news_letter_left_image}
              alt="group1"
              className="ml-[200px] mt-[30px]"
            />
          </div>
          <div className="flex flex-col items-center justify-center space-y-10 md:mt-12 mt-4 md:p-0 p-[16px]">
            <div className="lg:w-[714px] md:w-[700px] sm:w-[550px] w-[330px]  whitespace-pre-line font-CircularStdMedium font-normal md:leading-[63px] leading-[40px] lg:text-[54px] md:text-[40px] sm:text-[32px] text-[28px] text-center mt-6">
              <p> Begin your mentorship journey with impact.</p>
            </div>
            <div className="lg:w-[600px] md:w-[600px] sm:w-[500px] w-[330px] whitespace-pre-line font-CircularStdMedium font-normal leading-[33px] text-[22px] text-center text-[#9F9F9F] mt-4">
              Empower growth at every career stage with our expert mentorship
              network. Together, we elevate.
            </div>
            <div className="h-[84px] bg-[#fff] rounded-[258px] items-center justify-center mt-6 shadow-newsletter">
              <div className="flex justify-between">
                <div className="flex gap-4 items-center">
                  <div className="flex h-[84px] ml-[30px] items-center justify-center">
                    <img
                      src={hostedImageUrls.mail_icon}
                      className="w-[40px] h-[40px]"
                      alt="mail icon"
                    />
                  </div>
                  <input
                    placeholder="Enter your email"
                    className="text-[16px] text-black p-2 outline-none font-CircularStdMedium w-[200px] h-[40px]"
                    disabled
                  />
                </div>
                <div className="p-2 self-center">
                  <button className="md:w-[145px] w-[100px] h-[68px] flex-shrink-0 rounded-[100px] bg-gbpurple text-[18px] font-CircularStdMedium leading-7 font-normal text-white" disabled={true}>
                    Join now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:flex w-[382px] h-[504px] items-center justify-center hidden">
            <img
              src={hostedImageUrls.news_letter_right_image}
              alt="group1"
              className="mr-[230px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
