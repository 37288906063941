import hostedImageUrls from "../hosted_image";

export interface formStateType {
    first_name: string;
    last_name: string;
    country: string;
    is_email_verified: Boolean;
    phone_number: string;
    languages: string[];
    gender: "";
    date_of_birth: string;
    city: string;
    profile_picture_url: string;
    areas_of_expertise: string[];
    description: string;
    role: string;
    organization: string;
    is_experience: Boolean;
    number_of_likes: 0;
    MentorExperience: number;
}

export interface MentorFormError {
    first_name: string[];
    phone_number: string[];
    date_of_birth: string[];
    country: string[];
    city: string[];
    role: string[];
    organization: string[];
    description: string[];
    areas_of_expertise: string[];
    languages: string[];
    profile_picture_url: string[];
}

export const MentorImages = [
    {
        path: hostedImageUrls.plus_icon,
        url: hostedImageUrls.plus_icon,
        key: 0,
    },
    {
        path: hostedImageUrls.Mentor_M_1,
        url: hostedImageUrls.Mentor_M_1,
        key: 1,
    },
    {

        path: hostedImageUrls.Mentor_M_2,
        url: hostedImageUrls.Mentor_M_2,
        key: 2,
    },
    {
        path: hostedImageUrls.Mentor_M_3,
        url: hostedImageUrls.Mentor_M_3,
        key: 3,
    },
    {
        path: hostedImageUrls.Mentor_G_1,
        url: hostedImageUrls.Mentor_G_1,
        key: 4,
    },
    {

        path: hostedImageUrls.Mentor_G_2,
        url: hostedImageUrls.Mentor_G_2,
        key: 5,
    },
    {

        path: hostedImageUrls.Mentor_G_3,
        url: hostedImageUrls.Mentor_G_3,
        key: 6,
    },
    {
        path: hostedImageUrls.Mentor_M_4,
        url: hostedImageUrls.Mentor_M_4,
        key: 7,
    },
    {
        path: hostedImageUrls.Mentor_M_5,
        url: hostedImageUrls.Mentor_M_5,
        key: 8,
    },
    {
        path: hostedImageUrls.Mentor_M_6,
        url: hostedImageUrls.Mentor_M_6,
        key: 9,
    },

    {
        path: hostedImageUrls.Mentor_G_4,
        url: hostedImageUrls.Mentor_G_4,
        key: 10,
    },
    {

        path: hostedImageUrls.Mentor_G_5,
        url: hostedImageUrls.Mentor_G_5,
        key: 11,
    },
    {
        path: hostedImageUrls.Mentor_G_6,
        url: hostedImageUrls.Mentor_G_6,
        key: 12,
    },
]